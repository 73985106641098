import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    siteLogo: null,
    favIcon: null
}

const settingsslice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        siteSettingsImage: (state, action) => {
            state.siteLogo = action.payload
        },
        favIconImage: (state, action) => {
            state.favIcon = action.payload
        }
    }
})

export const { siteSettingsImage, favIconImage } = settingsslice.actions
export default settingsslice.reducer