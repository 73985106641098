import { useEffect, useState } from "react";
import AddEditLayoutModal from "../../containers/modals/AddEditLayoutModal";
import Select from "../form/Select";

const AppointmentStatusModal = ({
  title = "",
  show = false,
  totalCount = 0,
  editData = {},
  statusVal,
  setStatusVal,
  setEditData = () => {},
  setShow = () => {},
  list = [],
  setList = () => {},
  addType = "ASC",
  HandlechangeStatus,
}) => {
  const [disabled, setDisabled] = useState();
  const closeModal = () => {
    setShow(false);
    setDisabled(false);
    setEditData({});
  };

  useEffect(() => {
    if (editData && statusVal) {
      setStatusVal(editData?.status);
    }
  }, [editData]);

  return (
    <AddEditLayoutModal
      isBtn={false}
      title={title}
      show={show}
      modalSize="xl"
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
    >
      <div className="relative w-full">
        <div className="ml-4">
          <Select
            xPlacement={"left"}
            dropdownButtonClass={"!bg-white"}
            selectedValue={statusVal}
            dropdownData={[
              {
                _id: totalCount || 1000000,
                label: "Select",
                value: totalCount || 1000000,
              },
              ...[
                { _id: 2, label: "Pending", value: 2 },
                { _id: 1, label: "Approved", value: 1 },
                { _id: 3, label: "Canceled", value: 3 },
                { _id: 4, label: "Complete", value: 4 },
              ],
            ]}
            getSelectedValue={(e) => HandlechangeStatus(editData?._id, e.value)}
            label={"Select Status"}
          />
        </div>
        <div className="ml-4"></div>
        <div className="ml-4"></div>
        <div className="ml-4"></div>
        <div className="ml-4"></div>
      </div>
    </AddEditLayoutModal>
  );
};

export default AppointmentStatusModal;
