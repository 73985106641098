import { useState, useEffect, useRef } from "react";
import AddEditLayoutModal from "../../containers/modals/AddEditLayoutModal";
import Input from "../form/Input";
import { Editor } from "@tinymce/tinymce-react";
import { postData } from "../../services/api";
import toastr from "toastr";
import { tinymceApiKey, tinyMceInit } from "../../helpers";

const AddEditEmailTemplateModal = ({
  title = "",
  show = false,
  editData = {},
  setEditData = () => {},
  setShow = () => {},
  list = [],
  setList = () => {},
  addType = "ASC",
}) => {
  const editorRef = useRef(null);
  const [disabled, setDisabled] = useState();
  const [code, setCode] = useState();
  const [content, setContent] = useState();

  const [fromEmail, setFromEmail] = useState();
  const [toEmail, setToEmail] = useState();
  const [ccEmail, setCcEmail] = useState();



  useEffect(() => {
    if (editData?._id) {
      setCode(editData.code);
      setContent(editData.content);
      setToEmail(editData?.to_email);
      setFromEmail(editData?.from_email);
      setCcEmail(editData?.cc_email);
    }
  }, [editData]);


  const closeModal = () => {
    setShow(false);
    setCode("");
    setContent("");
    setToEmail("");
    setFromEmail("");
    setCcEmail("");
    setDisabled(false);
    setEditData({});
  };

  const onSubmit = async () => {
    setDisabled(true);

    const res = await postData(
      `email-template/${editData?._id ? "edit" : "add"}`,
      {
        code: code,
        content: content,
        from_email: fromEmail,
        to_email: toEmail,
        cc_email: ccEmail,
        id: editData?._id || null,
        // ordering: editData?._id ? editData.ordering : list.length,
        validationRules: {
  
        },
        validationMessages: {

        },
      }
    );

    if (res.status === 200) {
      if (!editData?._id) {
        if (addType !== "DESC") {
          setList((list) => ({
            ...list,
            data: [...list.data, res.data],
            count: list.count + 1,
          }));
        } else {
          setList((list) => ({
            ...list,
            data: [res.data, ...list.data],
            count: list.count + 1,
          }));
        }
      } else if (editData && editData?._id) {
        setList((list) => ({
          ...list,
          data: list.data.map((item) =>
            item._id !== editData._id ? item : res.data
          ),
        }));
      }

      closeModal();
      toastr.success(res.message);
    } else {
      setDisabled(false);
      toastr.error(res.message);
    }
  };

  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      modalSize="xxxl"
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
      onSubmit={onSubmit}
    >
      <div className="flex justify-between w-full">
        <div className="relative w-full">
          <div className="mb-3 flex">
            <div className="w-full mr-2">
              <Input
                label={"Code"}
                labelClasses={"!text-xs"}
                inputType={"text"}
                inputPlaceholder={"Enter Code"}
                inputValue={code}
                setInput={setCode}
                isDisabled={true}

                
                
              />
            </div>
            <div className="w-full ml-2">
              <Input
                label={"From Email"}
                labelClasses={"!text-xs"}
                inputType={"text"}
                inputPlaceholder={"Enter From Email"}
                inputValue={fromEmail}
                setInput={setFromEmail}
              />
            </div>
          </div>
          <div className="mb-3 flex">
            {
              editData?.mail_for !== 'admin' &&
              <div className="w-full mr-2">
                <Input
                  label={"To Email"}
                  labelClasses={"!text-xs"}
                  inputType={"text"}
                  inputPlaceholder={"Enter To Email"}
                  inputValue={toEmail}
                  setInput={setToEmail}
                />
              </div>
            }

            <div className="w-full ml-2">
              <Input
                label={"CC Email"}
                labelClasses={"!text-xs"}
                inputType={"text"}
                inputPlaceholder={"Enter CC Email"}
                inputValue={ccEmail}
                setInput={setCcEmail}
              />
            </div>
          </div>

          <div className="mb-3">
            <label className="text-sm font-medium text-slate-500 mb-1 mt-3">
              Content
            </label>
            <Editor
              apiKey={tinymceApiKey()}
              onInit={(evt, editor) => (editorRef.current = editor)}
              value={content}
              init={tinyMceInit()}
              onEditorChange={(newText) => setContent(newText)}
            />
          </div>
        </div>
      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditEmailTemplateModal;
