
import { useState, useEffect } from 'react';
import AddEditLayoutModal from '../../containers/modals/AddEditLayoutModal';
import Input from '../form/Input';

import { postData } from '../../services/api';

import toastr from 'toastr';
import Textarea from '../form/Textarea';
export default function AddEditPlans({title = '',show = false,editData = {},setEditData = () => {},setShow = () => {},list = [],setList = () => {},addType = 'ASC'}) {
    const [disabled, setDisabled] = useState()
    const [name, setName] = useState('');
    const [description,setDescription]=useState('')
    const [servings, setServings] = useState('')
    const [note, setNote] = useState('')
    useEffect(() => {
        if (editData?._id) {
          setName(editData.title)
          setDescription(editData.description)
          console.log(editData.description,"lhdfhi")
  
        }
        
      }, [ editData ])
      
      const closeModal = () => {
        setShow(false);
        setName('')
        setDescription('')
  
        setNote('')
        setServings('')
        setDisabled(false)
        setEditData({})
      };
 
      const onSubmit = async () => {
        setDisabled(true)
    
    
   
        const res = await postData(`crud/${editData?._id ? 'edit'  : 'add'}`, { 
          table_name: 'plan',
          data: {
            title: name,
            description:description,
            servings,
            note,
       
            id: editData?._id || null,
            ordering: editData?._id ? editData.ordering : list.length
          },
          validationRules: {
            'data.title': 'required',
            // 'data.ordering': 'required'
          },
          validationMessages: {
            'required.data.title': 'Title Field is required',
            // 'required.data.ordering': 'Ordering Field is required',
          }
        })
    
        if (res.status === 200) {
          if (!editData?._id) {
            if (addType !== 'DESC') {
              setList(list => ({
                ...list,
                data: [ ...list.data, res.data ],
                count: list.count + 1
              }))
            } else {
              setList(list => ({
                ...list,
                data: [ res.data, ...list.data, ],
                count: list.count + 1
              }))
            }
          } else if (editData && editData?._id) {
            setList(list => ({
              ...list,
              data: list.data.map(item => item._id !== editData._id ? item : res.data)
            }))
          }
     
    
          closeModal()
          toastr.success(res.message)
        } else {
          setDisabled(false)
          toastr.error(res.message)
        }
      }
  return (
    <AddEditLayoutModal
    title={title}
    show={show}
    modalSize="xl"
    setShow={setShow}
    onClose={closeModal}
    disabled={disabled}
    onSubmit={onSubmit}
  >
    <div className='relative w-full'>
 
      <div className="mb-3"></div>
      <Input
        label={'title '}
        labelClasses={'!text-xs'}
        inputType={'text'}
        inputPlaceholder={'Enter Title '}
        inputValue={name}
        setInput={setName}
      />
  

  
      <div className="mb-3"></div>
      <Textarea
        label={'Description'}
        labelClasses={'!text-xs'}
        inputType={'text'}
        inputPlaceholder={'Enter Description '}
        inputValue={description}
        setTextarea={setDescription}
        
      />
    </div>
   
 
  

  
    
  </AddEditLayoutModal>
  )
}
