import { useState, useRef } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { bottom } from '@popperjs/core';
import Flatpickr from 'react-flatpickr';

const TableDateFilter = ({
  xPlacement = '',
  placeholder = '',
  onChange = () => {},
}) => {
  const popperElRef = useRef(null);
  const flatPickr = useRef(null);

  const [filterOpen, setFilterOpen] = useState(false);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const [dateRange, setDateRange] = useState(null);

  const placements = {
    left: 'bottom-start',
    right: 'bottom-end',
    '': bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  return (
    <Menu as='div'>
      {({ open }) => (
        <>
          <Menu.Button
            ref={setTargetElement}
            className='flex items-center'
            onClick={() => setFilterOpen((open) => !open)}
          >
            <div className='relative bg-transparent flex items-center justify-center text-sm text-slate-400 w-6 h-6 focus:ring-0 transition-all duration-200'>
              <i className='fa-solid fa-fw fa-filter-list'></i>
            </div>
          </Menu.Button>
          <Transition
            show={filterOpen}
            leave='transition duration-75'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
            className='z-50'
            beforeEnter={() => setPopperElement(popperElRef.current)}
            afterLeave={() => setPopperElement(null)}
            ref={popperElRef}
            style={styles.popper}
            {...attributes.popper}
          >
            <Menu.Items className='w-60 origin-top-right bg-white border-0 divide-y divide-slate-100 rounded-md shadow ring-0'>
              <div className='py-1 max-h-60 divide-y divide-slate-100 overflow-auto scroll-smooth scrollbar'>
                <Flatpickr
                  ref={flatPickr}
                  value={dateRange}
                  placeholder={placeholder}
                  options={{
                    altInput: true,
                    altFormat: 'F j, Y',
                    mode: 'range',
                    dateFormat: 'd.m.y',
                  }}
                  onChange={(e) => {
                    onChange(e)
                    setDateRange(e)
                  }}
                />
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default TableDateFilter;
