import { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { SocketContext } from "../context/socket";
import { setTitle } from "../helpers/MetaTag";
import { postData } from "../services/api";
import toastr from "toastr";
import TableAlt from "../components/elements/table/TableAlt";
import ListLayout from "../containers/ListLayout";
import AppointmentDetailsModal from "../components/modals/AppointmentDetailsModal";
import AddEditAppointmentModal from "../components/modals/AddEditAppointmentModal";
import AppointmentStatusModal from "../components/modals/AppointmentStatusModal";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import { API_URL } from "../config/host";

export default function Category() {
  setTitle("Ryen bilpleie  | Appoinments");

  const socket = useContext(SocketContext);

  const user = useSelector((state) => state.auth.user);
  const searchParams = new URLSearchParams(window.location.search);
  const selctedCompanyId = searchParams ? searchParams.get("company_id") : 0;
  const selctedTypeId = searchParams ? searchParams.get("car_type_id") : 0;
  const selctedDriverId = searchParams ? searchParams.get("driver_id") : 0;
  const selctedServiceId = searchParams ? searchParams.get("service_id") : 0;
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [dateRange, setDateRange] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [statusVal, setStatusVal] = useState("");
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [list, setList] = useState({
    loading: false,
    data: [],
    count: 0,
  });
  const [sitesettingData, setSitesettingData] = useState(null);
  const [statusFilterData, setStatusFilterData] = useState([
    { _id: 1, name: "Approved", type: "checkbox", value: 1, isChecked: false },
    {
      _id: 2,
      name: "Pending",
      type: "checkbox",
      value: 2,
      isChecked: false,
    },
    {
      _id: 3,
      name: "Canceled",
      type: "checkbox",
      value: 3,
      isChecked: false,
    },
    {
      _id: 4,
      name: "Complete",
      type: "checkbox",
      value: 4,
      isChecked: false,
    },
  ]);
  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [companyId, setCompanyId] = useState(selctedCompanyId);
  const [driverId, setDriverId] = useState(selctedDriverId);
  const [cartTypeId, setCartTypeId] = useState(selctedTypeId);
  const [carServiceId, setCarServiceId] = useState(selctedServiceId);
  const [editData, setEditData] = useState({});

  useEffect(() => {
    const loadData = async () => {
      setList((list) => ({ ...list, loading: true }));
      const searchParams = {};
      if (keyword) {
        searchParams.title = { $regex: keyword, $options: "i" };
      }
      if (companyId !== 0 && companyId !== "" && companyId !== null) {
        searchParams.company_id = companyId;
      }

      if (driverId !== 0 && driverId !== "" && driverId !== null) {
        searchParams.driver_id = driverId;
      }
      if (cartTypeId !== 0 && cartTypeId !== "" && cartTypeId !== null) {
        searchParams.car_type_id = cartTypeId;
      }

      if (carServiceId !== 0 && carServiceId !== "" && carServiceId !== null) {
        searchParams.service_id = carServiceId;
      }

      if (
        statusFilterData.filter((item) => item.touched && item.isChecked).length
      ) {
        searchParams.status = {
          $in: statusFilterData
            .filter((item) => item.touched && item.isChecked)
            .map((item) => item.value),
        };
      }
      if (dateRange.start && dateRange.end) {
        searchParams.appointment_date = {
          $gte: new Date(dateRange.start),
          $lte: new Date(dateRange.end),
        };
      }

      const res = await postData("/crud/list", {
        table_name: "appointments",

        dateRange,
        queryParams: {
          ...searchParams,
        },
        selectParams: {
          name: true,
          status: true,
          short: true,
          email: true,

          car_model: true,
          appointment_date: true,
          appointment_start_time: true,
          appointment_end_time: true,
          ordering: true,
          created_at: true,
          appointment_id: {
            $cond: ["$appointment_id", "$appointment_id", null],
          },
          serviceInfo: {
            $cond: [
              { $arrayElemAt: ["$serviceInfo", 0] },
              { $arrayElemAt: ["$serviceInfo", 0] },
              null,
            ],
          },
          carTypeInfo: {
            $cond: [
              { $arrayElemAt: ["$carTypeInfo", 0] },
              { $arrayElemAt: ["$carTypeInfo", 0] },
              null,
            ],
          },
          company_name: {
            $cond: [
              { $arrayElemAt: ["$company_details.name", 0] },
              { $arrayElemAt: ["$company_details.name", 0] },
              null,
            ],
          },
          company_email: {
            $cond: [
              { $arrayElemAt: ["$company_details.email", 0] },
              { $arrayElemAt: ["$company_details.email", 0] },
              null,
            ],
          },
          driver_name: {
            $cond: [
              { $arrayElemAt: ["$driver_details.name", 0] },
              { $arrayElemAt: ["$driver_details.name", 0] },
              "N/A",
            ],
          },
          driver_email: {
            $cond: [
              { $arrayElemAt: ["$driver_details.email", 0] },
              { $arrayElemAt: ["$driver_details.email", 0] },
              "N/A",
            ],
          },
          price: {
            $concat: [{ $toString: "$price" }, "Kr"],
          },
          exact_price: "$price",
          driver_id: { $cond: ["$driver_id", "$driver_id", null] },
          company_id: { $cond: ["$company_id", "$company_id", null] },
        },
        join: [
          {
            toTable: "services",
            fromField: "service_id",
            toField: "_id",
            selectField: "serviceInfo",
            justOne: true,
            projection: {
              name: true,
            },
          },
          {
            toTable: "cartypes",
            fromField: "car_type_id",
            toField: "_id",
            selectField: "carTypeInfo",
            justOne: true,
            projection: {
              title: true,
            },
          },
          {
            toTable: "users",
            fromField: "company_id",
            toField: "_id",
            selectField: "company_details",
            projection: {
              name: true,
              email: true,
            },
          },
          {
            toTable: "users",
            fromField: "driver_id",
            toField: "_id",
            selectField: "driver_details",
            projection: {
              name: true,
              email: true,
            },
          },
        ],
        sortQuery:
          sortField && sortType
            ? { [sortField]: sortType }
            : { appointment_date: -1 },
        limit,
        offset,
      });
      if (res.status === 200) {
        setList((list) => ({
          ...list,
          data: res.data.list,
          loading: false,
          count: res.data.count,
        }));
      } else {
        setList((list) => ({ ...list, loading: false }));
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user?._id,
    keyword,
    limit,
    offset,
    sortField,
    sortType,
    dateRange,
    cartTypeId,
    carServiceId,
    companyId,
    driverId,

    // eslint-disable-next-line react-hooks/exhaustive-deps
    statusFilterData.filter((item) => item.touched && item.isChecked).length,
  ]);

  useEffect(() => {
    const loadData = async () => {
      const result = await postData("site-settings/details", {});
      if (result.status === 200) {
        setSitesettingData(result.data);
      }
    };
    loadData();
  }, []);

  const tableHeadData = [
    {
      _id: 11,
      width: 15,
      title: "Appointment Id",
      name: "appointment_id",
      align: "left",
      sortable: false,
      filterable: false,
      onClick: (id) => {
        console.log(editData, "editdataclick");
        setShowModal(true);
        setEditData(list.data.find((item) => item._id === id) || {});
      },
    },
    {
      _id: 13,
      width: 15,
      title: "company name",
      name: "company_name",
      align: "left",
      sortable: true,
      filterable: false,
      onClick: (id) => {
        console.log(editData, "editdataclick");
        setShowModal(true);
        setEditData(list.data.find((item) => item._id === id) || {});
      },
    },
    {
      _id: 13,
      width: 15,
      title: "company email",
      name: "company_email",
      align: "left",
      sortable: true,
      filterable: false,
      onClick: (id) => {
        console.log(editData, "editdataclick");
        setShowModal(true);
        setEditData(list.data.find((item) => item._id === id) || {});
      },
    },

    {
      _id: 1,
      width: 15,
      title: "Driver Name",
      name: "driver_name",
      align: "left",
      sortable: true,
      filterable: false,
      onClick: (id) => {
        console.log(editData, "editdataclick");
        setShowModal(true);
        setEditData(list.data.find((item) => item._id === id) || {});
      },
    },
    {
      _id: 2,
      width: 15,
      title: "Driver Email",
      name: "driver_email",
      align: "left",
      sortable: true,
      filterable: false,
      onClick: (id) => {
        console.log(editData, "editdataclick");
        setShowModal(true);
        setEditData(list.data.find((item) => item._id === id) || {});
      },
    },
    {
      _id: 3,
      width: 15,
      title: "status",
      name: "status",
      align: "center",
      sortable: true,
      filterable: true,
      filterOptions: statusFilterData,

      onFilterChange: (id) => {
        setStatusFilterData((data) =>
          data.map((item) =>
            item._id !== id
              ? item
              : {
                  ...item,
                  isChecked: !item.isChecked,
                  touched: true,
                }
          )
        );
      },
    },
    {
      _id: 4,
      width: 15,
      title: "Car Type",
      name: "carTypeInfo",
      align: "left",
      sortable: true,
      dataType: "object",
      dataKey: "title",
      filterable: false,
    },
    {
      _id: 4,
      width: 15,
      title: "Car Service",
      name: "serviceInfo",
      dataType: "object",
      dataKey: "name",
      align: "left",
      sortable: true,
      filterable: false,
    },

    {
      _id: 4,
      width: 15,
      title: "Car model",
      name: "car_model",
      align: "left",
      sortable: true,
      filterable: false,
    },

    {
      _id: 5,
      width: 15,
      title: "Price",
      name: "price",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 10,
      width: 15,
      title: "Created At",
      name: "created_at",
      align: "left",
      sortable: true,
      filterable: false,
    },

    {
      _id: 6,
      width: 15,
      title: "Appointment Date",
      name: "appointment_date",
      align: "left",
      sortable: true,
      dateFilterable: true,
      onDateChange: (e) => {
        if (e && e.length && e.length > 1) {
          setDateRange({
            start: e[0],
            end: e[1],
          });
        }
      },
    },
    {
      _id: 7,
      width: 15,
      title: "Start time",
      name: "appointment_start_time",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 8,
      width: 15,
      title: "End time",
      name: "appointment_end_time",
      align: "left",
      sortable: true,
      filterable: false,
    },

    {
      _id: 9,
      action: true,
    },
  ];

  function openModal(id) {
    setShowStatusModal(true);
    setEditData(list.data.find((item) => item._id === id) || {});
  }
  // function openView(id) {
  //   setShowModal(true);
  //   setEditData(list.data.find((item) => item._id === id) || {});
  // }

  const HandlechangeStatus = async (id, statusVal) => {
    const res = await postData("/appointment/status-change", {
      id: id,
      status: statusVal,
    });

    if (res.status === 200) {
      // Insert notification data
      setStatusVal(statusVal);
      const appointmentSingleData = list.data.find((item) => item._id === id);

      if (appointmentSingleData) {
        if (appointmentSingleData.driver_id) {
          await postData("crud/add", {
            table_name: "notification",
            data: {
              sender_id: user?._id,
              receiver_id: appointmentSingleData.driver_id,
              title: appointmentSingleData.serviceInfo?.name || "",
              text: `${
                appointmentSingleData.serviceInfo?.name || ""
              } appointment status has been updated to ${
                statusVal === 1
                  ? "Approved"
                  : statusVal === 2
                  ? "Pending"
                  : statusVal === 3
                  ? "Cancelled"
                  : statusVal === 4
                  ? "Completed"
                  : ""
              }.`,
              status: statusVal,
              appointment_id: appointmentSingleData._id,
              created_by: user?._id,
            },
          });

          socket.emit("getMessageEvent", {
            user_id: appointmentSingleData.driver_id,
          });
        }

        if (appointmentSingleData.company_id) {
          await postData("crud/add", {
            table_name: "notification",
            data: {
              sender_id: user?._id,
              receiver_id: appointmentSingleData.company_id,
              title: appointmentSingleData.serviceInfo?.name || "",
              text: `${
                appointmentSingleData.serviceInfo?.name || ""
              } appointment status has been updated to ${
                statusVal === 1
                  ? "Approved"
                  : statusVal === 2
                  ? "Pending"
                  : statusVal === 3
                  ? "Cancelled"
                  : statusVal === 4
                  ? "Completed"
                  : ""
              }.`,
              status: statusVal,
              appointment_id: appointmentSingleData._id,
              created_by: user?._id,
            },
          });

          socket.emit("getMessageEvent", {
            user_id: appointmentSingleData.company_id,
          });
        }
      }

      setList((list) => ({
        ...list,
        data: list.data.map((item) =>
          item._id !== id
            ? item
            : {
                ...item,
                status: statusVal,
                touched: true,
              }
        ),
      }));
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  const actionData = () => [
    {
      name: "View",
      icon: "fa-eye",
      type: "button",
      onClick: (id) => {
        setShowModal(true);
        setEditData(list.data.find((item) => item._id === id) || {});
      },
    },
    {
      name: "Status Change",
      icon: "fa-solid fa-check-to-slot",
      type: "button",
      onClick: (id) => {
        setShowStatusModal(true);
        setEditData(list.data.find((item) => item._id === id) || {});
      },
    },
  ];

  const handelReset = () => {};

  const styles = StyleSheet.create({
    body: {
      padding: "15px",
    },
    image: {
      width: "120px",
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
    },
    table: {
      display: "table",
      width: "100%",
      marginTop: 20,
      borderStyle: "solid",
      borderWidth: 1,
      borderBottom: 0,
    },
    tableRow: {
      display: "table-row",
      padding: "5px",
      textAlign: "left",
      flexDirection: "row",
      borderBottom: 1,
    },
    tableName: {
      display: "table-cell",
      width: "30%",
    },
    tableText: {
      fontSize: 10,
    },
    tableDate: {
      display: "table-cell",
      width: "20%",
    },
    tableType: {
      display: "table-cell",
      width: "20%",
    },
    tableServices: {
      display: "table-cell",
      width: "20%",
    },
    tablePrice: {
      display: "table-cell",
      width: "10%",
    },
    tableFlex: {
      display: "flex",
    },
    tableNameCell: {
      textAlign: "left",
      fontSize: 10,
    },

    tableCell: {
      fontSize: 10,
      textAlign: "left",
    },
    heading: {
      textAlign: "center",
      fontSize: 20,
      color: "black",
      marginTop: 10,
    },
    subText: {
      fontSize: 10,
      maxLines: 2,
      lineHeight: 1.4,
    },
  });
  // const changeStatus = async (id) => {
  //   const res = await postData("/crud/statusChange", {
  //     table_name: "services",
  //     id: [id],
  //     column_name: "status",
  //     column_value: !(
  //       list.data.find((item) => item._id === id).status || false
  //     ),
  //   });
  const MyDocument = () => {
    let total = 0;

    return (
      <Document>
        <Page style={styles.body}>
          <View
            style={[
              styles.tableFlex,
              { flexDirection: "row", justifyContent: "space-between" },
            ]}
          >
            <View
              style={[
                styles.infoData,
                {
                  flexGrow: "2",
                  width: 250,
                  textAlign: "left",
                  marginRight: 35,
                },
              ]}
            >
              <Image
                style={styles.image}
                src={`${API_URL}storage/alternate-carwash-logo.png`}
              />
            </View>
            <View
              style={[
                styles.infoData,
                {
                  flexGrow: "2",
                  width: 100,
                  textAlign: "right",
                  marginLeft: 35,
                },
              ]}
            >
              <Text
                style={[
                  styles.heading,
                  { textAlign: "right", fontSize: 16, marginBottom: 5 },
                ]}
              >
                {sitesettingData?.title}
              </Text>
              <Text
                style={[styles.subText, { flexWrap: "wrap", display: "flex" }]}
              >
                {sitesettingData?.address}
              </Text>
              <Text style={styles.subText}>{sitesettingData?.phone}</Text>
              <Text style={styles.subText}>{sitesettingData?.email}</Text>
            </View>
          </View>
          {/* <View style={[styles.infoData, { marginTop: 5 }]}>
            <Text style={[styles.heading, { textAlign: "left", fontSize: 16, marginBottom: 5 }]}>Invoice Details</Text>
            <Text style={styles.subText}>Invoice Number: #INV2895162</Text>
            <Text style={styles.subText}>John Doe</Text>
            <Text style={styles.subText}>Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016</Text>
            <Text style={styles.subText}>+91 123 456 789</Text>
            <Text style={styles.subText}>youremail@gmail.com</Text>
          </View> */}

          <View
            style={[
              styles.tableFlex,
              {
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
                marginTop: 5,
              },
            ]}
          >
            <Text style={[styles.heading, { fontSize: 16 }]}>
              Appointment Details
            </Text>
            {/* <Text style={[styles.subText, { flexWrap: "wrap", display: "flex", fontSize: 12, fontWeight: 500 }]}>Date : 12 Jan 2023 - 12 Feb 2023</Text> */}
          </View>

          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableName}>
                <Text style={styles.tableNameCell}>Appointment ID</Text>
              </View>
              <View style={styles.tableName}>
                <Text style={styles.tableNameCell}>Name/Email</Text>
              </View>
              <View style={styles.tableType}>
                <Text style={styles.tableCell}>Car Type</Text>
              </View>
              <View style={styles.tableServices}>
                <Text style={styles.tableCell}>Service Name</Text>
              </View>
              <View style={styles.tableDate}>
                <Text style={styles.tableCell}>Date & Time</Text>
              </View>
              <View style={styles.tablePrice}>
                <Text style={styles.tableCell}>Price</Text>
              </View>
            </View>
            {list.data.map((item) => {
              total = total + item.exact_price;

              return (
                <>
                  <View style={styles.tableRow}>
                    <View style={styles.tableName}>
                      <View style={[styles.tableFlex, { textAlign: "left" }]}>
                        <Text style={styles.tableText}>
                          {item.appointment_id}
                        </Text>
                        <Text style={styles.tableText}>
                          {" "}
                          {item.status === 1
                            ? "Approved"
                            : item.status === 2
                            ? "Pending"
                            : item.status === 3
                            ? "Cancelled"
                            : item.status === 4
                            ? "Completed"
                            : ""}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.tableName}>
                      <View style={styles.tableFlex}>
                        <Text style={styles.tableText}>{item.name}</Text>
                        <Text style={styles.tableText}>{item.email}</Text>
                      </View>
                    </View>
                    <View style={[styles.tableType, { textAlign: "left" }]}>
                      <Text style={styles.tableCell}>
                        {item.carTypeInfo?.title}
                      </Text>
                    </View>
                    <View style={styles.tableServices}>
                      <Text style={styles.tableCell}>
                        {item.serviceInfo?.name}
                      </Text>
                    </View>
                    <View style={styles.tableDate}>
                      <Text style={styles.tableCell}>
                        {moment(item.appointment_date).format("MMM Do YY")}
                      </Text>
                      <Text style={styles.tableText}>
                        {moment(item.appointment_start_time).format("h:mm a")} -{" "}
                        {moment(item.appointment_end_time).format("h:mm a")}
                      </Text>
                    </View>
                    <View style={styles.tablePrice}>
                      <Text style={styles.tableCell}>{item.price}</Text>
                    </View>
                  </View>
                </>
              );
            })}
            <View
              style={[
                styles.tableRow,
                {
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                },
              ]}
            >
              <Text style={styles.heading}>Total Amonut</Text>

              
              <Text style={styles.priceHeading}>{total ? total.toFixed(2) : 0}Kr</Text>
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  console.log("statusVal", statusVal)

  return (
    <>
      <ListLayout
        title="Appoinments"
        addName="Reset "
        onClick={() => handelReset()}
        MyDocument={MyDocument}
        styles={styles}
        showAddButton={false}
        limit={limit}
        setLimit={setLimit}
        setKeyword={setKeyword}
        setCompanyId={setCompanyId}
        setDriverId={setDriverId}
        cartTypeId={cartTypeId}
        carServiceId={carServiceId}
        setCarServiceId={setCarServiceId}
        setCartTypeId={setCartTypeId}
        driverId={driverId}
        keyword={keyword}
        companyId={companyId}
        noOfPage={Math.ceil(list.count / limit)}
        onPageChange={(val) => setOffset(val.selected * limit)}
        setOffset={setOffset}
      >
        <TableAlt
          tableHeadData={tableHeadData}
          tableData={list.data}
          loading={list.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
          actionData={actionData}
          setList={setList}
          isDraggable={false}
          functions={openModal}
        />
      </ListLayout>
      <AddEditAppointmentModal
        show={showAddModal}
        setShow={setShowAddModal}
        title={Object.keys(editData).length > 0 ? "Add Appointment" : ""}
        list={list.data}
        setList={setList}
        editData={editData}
        setEditData={setEditData}
      />
      {/* {statusVal !== 4 && ( */}
        <AppointmentStatusModal
          show={showStatusModal}
          setShow={setShowStatusModal}
          title={"View Appointment Status"}
          list={list.data}
          setList={setList}
          editData={editData}
          setEditData={setEditData}
          HandlechangeStatus={HandlechangeStatus}
          setStatusVal={setStatusVal}
          statusVal={statusVal}
        />
    {/* )}  */}

      <AppointmentDetailsModal
        show={showModal}
        setShow={setShowModal}
        title={
          Object.keys(editData).length > 0 ? "View Appointment Details" : ""
        }
        list={list.data}
        setList={setList}
        editData={editData}
        setEditData={setEditData}
      />
    </>
  );
}
