import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { setTitle } from "../helpers/MetaTag";

import ListLayout from "../containers/ListLayout";
import { postData } from "../services/api";

import toastr from "toastr";

import { API_URL } from "../config/host";

import TableAlt from "../components/elements/table/TableAlt";
import AddEditCarServices from "../components/modals/AddEditCarServices";
import { useNavigate } from "react-router-dom";

export default function Category() {
  setTitle("Ryen bilpleie  | Car Services")
  const user = useSelector((state) => state.auth.user);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [dateRange, setDateRange] = useState({});
  const [list, setList] = useState({
    loading: false,
    data: [],
    count: 0,
  });
  const [statusFilterData, setStatusFilterData] = useState([
    { _id: 1, name: "Active", type: "checkbox", value: true, isChecked: false },
    {
      _id: 2,
      name: "Inactive",
      type: "checkbox",
      value: false,
      isChecked: false,
    },
  ]);
  const navigate = useNavigate();
  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState({});


  useEffect(() => {
  
    const loadData = async () => {
      setList((list) => ({ ...list, loading: true }));
      const searchParams = {};
      if (keyword) {
        searchParams.name = { $regex: keyword, $options: "i" };
      }

      if (
        statusFilterData.filter((item) => item.touched && item.isChecked).length
      ) {
        searchParams.status = {
          $in: statusFilterData
            .filter((item) => item.touched && item.isChecked)
            .map((item) => item.value),
        };
      }

      const res = await postData("/crud/list", {
        table_name: "services",

        dateRange,
        queryParams: {
          deleted_at: null,
          ...searchParams,
        },
        selectParams: {
          name: true,
          status: true,
          short: true,
          description: true,
          image: {
            $cond: [
              { $arrayElemAt: ["$image", 0] },
              { $concat: [API_URL, { $arrayElemAt: ["$image.path", 0] }] },
              null,
            ],
          },
          ordering: true,
          created_at: true,
         
        },
        join: [
          {
            toTable: "files",
            fromField: "image_id",
            toField: "_id",
            selectField: "image",
            justOne: true,
            projection: {
              path: true,
            },
          },
        ],
        sortQuery: sortField && sortType ? { [sortField]: sortType } : { ordering: 1 },
        limit,
        offset
      });
      if (res.status === 200) {
        setList((list) => ({
          ...list,
          data: res.data.list,
          loading: false,
          count: res.data.count,
        }));
      } else {
        setList((list) => ({ ...list, loading: false }));
      }
    };

    loadData();
   
  }, [
    user?._id,
    
    keyword,
    limit,
    offset,
    sortField,
    sortType,
    dateRange,
    statusFilterData.filter((item) => item.touched && item.isChecked).length,
  ]);

  const Appointments = (id) => {
    navigate(`/appoinments?service_id=${id}`);
  };

  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      title: "Image",
      name: "image",
      align: "left",
      sortable: false,
      filterable: false,
    },
    {
      width: 15,
      title: "services",
      name: "name",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      width: 15,
      title: "Description",
      name: "short",
      align: "left",
      sortable: true,
      filterable: false,
    },

    {
      width: 15,
      title: "Created At",
      name: "created_at",
      align: "left",
      sortable: true,
      dateFilterable: false,
      onDateChange: (e) => {
        if (e && e.length && e.length > 1) {
          setDateRange({
            start: e[0],
            end: e[1],
          });

        }
      },
    },
    {
      width: 15,
      title: "Status",
      name: "status",
      align: "left",
      sortable: true,
      filterable: true,
      filterOptions: statusFilterData,
      onFilterChange: (id) => {

        setStatusFilterData((data) =>
          data.map((item) =>
            item._id !== id
              ? item
              : {
                  ...item,
                  isChecked: !item.isChecked,
                  touched: true,
                }
          )
        );
      },
    },
    {
      action: true,
    },
  ];
  const changeStatus = async (id) => {
    const res = await postData("/crud/statusChange", {
      table_name: "services",
      id: [id],
      column_name: "status",
      column_value: !(
        list.data.find((item) => item._id === id).status || false
      ),
    });

    if (res.status === 200) {
      setList((list) => ({
        ...list,
        data: list.data.map((item) =>
          item._id !== id
            ? item
            : {
                ...item,
                status: !(
                  list.data.find((item) => item._id === id).status || false
                ),
                touched: true,
              }
        ),
      }));
      toastr.success(res.message)
    } else {
      toastr.error(res.message);
    }
  };

  const actionData = (item) => [
    {
      name: "Edit",
      icon: "fa-pen-to-square",
      type: "button",
      onClick: (id) => {
        setShowModal(true);
        setEditData(list.data.find((item) => item._id === id) || {});
      },
    },
    {
      name: item.status ? "Inactive" : "Active",
      icon: item.status ? "fa-circle-exclamation" : "fa-circle-check",
      type: "button",
      onClick: changeStatus,
    },
    {
      name: "Delete",
      icon: "fa-trash-can",
      type: "button",
      actionType: "delete",
      deleteLink: "/crud/delete",
      deletePayload: { id: [item._id], table_name: "services" },
    },
    {
      name: "Appointments",
      icon: "fa-light fa-fw fa-solid fa-calendar-days",
      type: "button",
      onClick: (id) => Appointments(id),
    },
  ];
  const onDrag = async (data = []) => {
    const res = await postData("/crud/ordering", {
      table_name: "services",
      ordering_data: data.map((item, i) => ({ id: item._id, ordering: i })),
    });

    if (res.status === 200) {
      toastr.success(res.message);
    }
  };
  
  return (
    <>
      <ListLayout
        title="Services"
        addName="Add Services"
        onAdd={() => setShowModal(true)}
        limit={limit}
        setLimit={setLimit}
        setKeyword={setKeyword}
        keyword={keyword}
        noOfPage={Math.ceil(list.count / limit)}
        onPageChange={(val) => setOffset(val.selected * limit)}
        setOffset={setOffset}
        
      >
        <TableAlt
          tableHeadData={tableHeadData}
          tableData={list.data}
          loading={list.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
          actionData={actionData}
          setList={setList}
          isDraggable={true}
          onDrag={onDrag}
          functions={changeStatus}
        />
      </ListLayout>
      <AddEditCarServices
        show={showModal}
        setShow={setShowModal}
        title={
          Object.keys(editData).length > 0 ? "Edit Service" : "Add Service"
        }
        list={list.data}
        setList={setList}
        editData={editData}
        setEditData={setEditData}
      />
    </>
  );
}
