import { useEffect, useState } from "react";
import SidebarMenuBlocks from "../components/navigation/SidebarMenuBlocks";
import { postData } from "../services/api";
import { connect } from "react-redux";
import Image from "../components/elements/Image";


const DefaultSidebar = ({ isMenuOpen, setIsMenuOpen, siteState }) => {
  const [logo, setLogo] = useState(null);
  const [favicon, setFavicon] = useState(null);

  console.log(logo,"hhh")

  console.log(siteState, "buddy default")

  useEffect(() => {
    const loadData = async () => {
      const result = await postData('site-settings/details', {})
      if (result.status === 200) {
        setLogo(result.data.logo);
        setFavicon(result.data.favicon);



      }

    }
    loadData()
  }, [])
  const sidebarMenu = [
    {
      _id: "1",
      menuTitle: "User Managemant",
      menuData: [
        {
          _id: "1-1",
          menuLabel: "Dashboard",
          menuLink: "/dashboard",
          menuIcon: "fa-light fa-gauge",
          menuIconColor: "text-amber-300",
        },
        {
          _id: "1-1",
          menuLabel: "Admin",
          menuLink: "/admin",
          menuIcon: "fa-light fa-user",
          menuIconColor: "text-teal-400",
        },
        {
          _id: "1-1",
          menuLabel: "Companies",
          menuLink: "/users",
          menuIcon: "fa-light fa-buildings",
          menuIconColor: "text-blue-500",
        },
        {
          _id: "1-1",
          menuLabel: "Drivers",
          menuLink: "/drivers",
          menuIcon: "fa-light fa-car",
          menuIconColor: "text-fuchsia-500",
        },
      ],
    },
    {
      _id: "2",
      menuTitle: "Booking Managemant",
      menuData: [
        {
          _id: "2-1",
          menuLabel: "Car Types",
          menuLink: "/car_types",
          menuIcon: "fa-light fa-car-bus",
          menuIconColor: "text-amber-500",
        },
        {
          _id: "2-2",
          menuLabel: "Car Services",
          menuLink: "/car-services",
          menuIcon: "fa-light fa-car-wrench",
          menuIconColor: "text-violet-500",
        },
        {
          _id: "2-3",
          menuLabel: "Services Plan",
          menuLink: "/services_plan",
          menuIcon: "fa-light fa-money-check-dollar",
          menuIconColor: "text-rose-500",
        },
        {
          _id: "2-5",
          menuLabel: "Time Slots ",
          menuLink: "/time_slots",
          menuIcon: "fa-light fa-clock",
          menuIconColor: "text-sky-500",
        },

      ],
    },
    {
      _id: "3",
      menuTitle: "Appointment Managemant",
      menuData: [
        {
          _id: "3-1",
          menuLabel: "List",
          menuLink: "/appoinments",
          menuIcon: "fa-light fa-list",
          menuIconColor: "text-yellow-500",
        },
        {
          _id: "3-2",
          menuLabel: "Calender",
          menuLink: "/calender-view",
          menuIcon: "fa-light fa-calendar-week",
          menuIconColor: "text-cyan-500",
        },
        {
          _id: "3-2",
          menuLabel: "Invoices",
          menuLink: "/invoices",
          menuIcon: "fas fa-file-invoice",
          menuIconColor: "text-red-500",
        },

      ],
    },

    {
      _id: "3",
      menuTitle: "CMS",
      menuData: [
        {
          _id: "3-3",
          menuLabel: "Pages",
          menuLink: "/pages",
          menuIcon: "fa-light fa-file-lines",
          menuIconColor: "text-yellow-500",
        },
        {
          _id: "3-1",
          menuLabel: "Email Templates",
          menuLink: "/email-templates",
          menuIcon: "fa-envelope",
          menuIconColor: "text-orange-500",
        },
        {
          _id: "3-2",
          menuLabel: "Settings",
          menuLink: "/site-settings",
          menuIcon: "fa-wrench",
          menuIconColor: "text-teal-500",
        },
      ],
    },
  ];
  return (
    <>
      <div className="relative w-full bg-slate-900 divide-y divide-slate-800">
        <div
          className={
            "relative flex items-center gap-4 h-16 transition-all duration-100 " +
            (isMenuOpen ? "px-3" : "px-4")
          }
        >
          {isMenuOpen?(
            <div className="h-10 w-full">
              <Image
                src={siteState.siteLogo instanceof File ? URL.createObjectURL(siteState.siteLogo) : favicon}
                alt={'Admin'}
                width={'100%'}
                height={'100%'}
                effect={'blur'}
                classes={'object-contain'}
                id={'headerImg'}
              />
            </div>
          ):(
          <div className="h-10 w-full">
            {
              siteState.siteLogo === null ?
              <Image
                src={logo}
                alt={'Admin'}
                width={'100%'}
                height={'100%'}
                effect={'blur'}
                classes={'object-contain'}
                id={'headerImg'}
              />
              :
              <Image
                src={siteState.siteLogo instanceof File ? URL.createObjectURL(siteState.siteLogo) : logo}
                alt={'Admin'}
                width={'100%'}
                height={'100%'}
                effect={'blur'}
                classes={'object-contain'}
                id={'headerImg'}
              />
            }
          </div>
          )}
          
          
        </div>
        <div
          className={
            "relative overflow-auto scroll-smooth scrollbar scrollbar-1 h-[calc(100vh-4rem)] transition-all duration-100 " +
            (isMenuOpen ? "divide-y divide-slate-800" : "")
          }
        >
          {sidebarMenu.map((item) => (
            <SidebarMenuBlocks
              key={item._id}
              menuTitle={item.menuTitle}
              menuData={item.menuData}
              isMenuOpen={isMenuOpen}
            />
          ))}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    siteState: state.siteLogo
  }
}

export default connect(mapStateToProps)(DefaultSidebar);
