
import { useState, useEffect } from 'react';
import AddEditLayoutModal from '../../containers/modals/AddEditLayoutModal';
import Input from '../form/Input';

import { postData } from '../../services/api';
import { convertToBase64Async } from '../../helpers';
import toastr from 'toastr';
export default function ManageCarTypes({title = '',show = false,editData = {},setEditData = () => {},setShow = () => {},list = [],setList = () => {},addType = 'ASC'}) {
    const [disabled, setDisabled] = useState()
    const [name, setName] = useState('');
    const [description, setDescription] = useState('')
    const [servings, setServings] = useState('')
    const [note, setNote] = useState('')
    const [image, setImage] = useState(null)
    useEffect(() => {
        if (editData?._id) {
          setName(editData.title)
  
        }
      }, [ editData ])
      
      const closeModal = () => {
        setShow(false);
        setName('')
        setDescription('')
        setImage(null)
        setNote('')
        setServings('')
        setDisabled(false)
        setEditData({})
      };
      const onImageChange = e => {
        if (e.target.files.length) {
          setImage(e.target.files[0])
        }
      }
      const onSubmit = async () => {
        setDisabled(true)
    
        let base64 = ''
    
        if (image) {
          base64 = await convertToBase64Async(image)
        }
    
        const res = await postData(`crud/${editData?._id ? 'edit'  : 'add'}`, { 
          table_name: 'carType',
          data: {
            title: name,
            servings,
            description,
            note,
            image: base64,
            id: editData?._id || null,
            ordering: editData?._id ? editData.ordering : list.length
          },
          validationRules: {
            'data.title': 'required',
            // 'data.ordering': 'required'
          },
          validationMessages: {
            'required.data.title': 'Title Field is required',
            // 'required.data.ordering': 'Ordering Field is required',
          }
        })
    
        if (res.status === 200) {
          if (!editData?._id) {
            if (addType !== 'DESC') {
              setList(list => ({
                ...list,
                data: [ ...list.data, res.data ],
                count: list.count + 1
              }))
            } else {
              setList(list => ({
                ...list,
                data: [ res.data, ...list.data, ],
                count: list.count + 1
              }))
            }
          } else if (editData && editData?._id) {
            setList(list => ({
              ...list,
              data: list.data.map(item => item._id !== editData._id ? item : res.data)
            }))
          }
     
    
          closeModal()
          toastr.success(res.message)
        } else {
          setDisabled(false)
          toastr.error(res.message)
        }
      }
  return (
    <AddEditLayoutModal
    title={title}
    show={show}
    modalSize="xl"
    setShow={setShow}
    onClose={closeModal}
    disabled={disabled}
    onSubmit={onSubmit}
  >
    <div className='relative w-full'>
 
      <div className="mb-3"></div>
      <Input
        label={'Car Type '}
        labelClasses={'!text-xs'}
        inputType={'text'}
        inputPlaceholder={'Enter Car type '}
        inputValue={name}
        setInput={setName}
      />
  

  
    </div>
  </AddEditLayoutModal>
  )
}
