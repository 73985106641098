const Search = ({ placeholder, classes, setInput, value = '' }) => {
  return (
    <div className='relative flex h-10 w-full bg-white rounded border border-slate-200'>
      <div className='w-10 h-10 flex items-center justify-center text-slate-400'>
        <i className='fa-regular fa-fw fa-magnifying-glass'></i>
      </div>
      <input
        type='text'
        value={value}
        placeholder={placeholder}
        className={
          '!border-0 !ring-0 bg-transparent text-slate-600 text-sm p-0 pr-3 ' +
          classes
        }
        onChange={(e) => setInput(e.target.value)}
      />
    </div>
  );
};

export default Search;
