import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { setTitle } from "../helpers/MetaTag";

import TableAlt from "../components/elements/table/TableAlt";
import ListLayout from "../containers/ListLayout";
import { postData } from "../services/api";


import AddEditEmailTemplateModal from "../components/modals/AddEditEmailTemplateModal";

export default function EmailTemplates() {
  const user = useSelector((state) => state.auth.user);
  setTitle("Ryen bilpleie  | Email Template")

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");
 
  const [list, setList] = useState({
    loading: false,
    data: [],
    count: 0,
  });

  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState({});

  useEffect(() => {
    const loadData = async () => {
      setList((list) => ({ ...list, loading: true }));
      const searchParams = {};
      if (keyword) {
        searchParams.subject = { $regex: keyword, $options: "i" };
      }

  

      const res = await postData("/email-template/list", {
        
      });
      if (res.status === 200) {
        setList((list) => ({
          ...list,
          data: res.data,
          loading: false,
          count: res.data.count,
        }));
      } else {
        setList((list) => ({ ...list, loading: false }));
      }
    };

    loadData();
   
  }, [
    user?._id,
    keyword,
    limit,
    offset,
    sortField,
    sortType,
 
  ]);

  


  const tableHeadData = [
    {
      width: 15,
      title: "Subject",
      name: "subject",
      align: "left",
      sortable: true,
      filterable: false,
      
    },
    {
      width: 15,
      title: "Note",
      name: "code",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      width: 15,
      title: "From Mail",
      name: "from_email",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      width: 15,
      title: "Mail For",
      name: "mail_for",
      align: "left",
    },

    {
      width: 16,
      title: "Created At",
      name: "created_at",
      align: "left",
      sortable: true,
      dateFilterable: true,

    },
    {
      action: true,
    },
  ];
  
  const actionData = (item) => [
    {
      name: "Edit",
      icon: "fa-pen-to-square",
      type: "button",
      actionType: "edit",
      onClick: (id) => {
        setShowModal(true);
        setEditData(list.data.find((item) => item._id === id) || {});
      },
    },
    

  ];

  return (
    <>
      <ListLayout
        title="Email Templates"
        
        onAdd={() => setShowModal(true)}
        showAddButton={false}
        limit={limit}
        setLimit={setLimit}
        setKeyword={setKeyword}
        keyword={keyword}
        noOfPage={Math.ceil(list.count / limit)}
        onPageChange={(val) => setOffset(val.selected * limit)}
      >
        <TableAlt
          tableHeadData={tableHeadData}
          tableData={list.data}
          loading={list.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
          actionData={actionData}
          setList={setList}
          isDraggable={false}
        />
      </ListLayout>
      <AddEditEmailTemplateModal
        show={showModal}
        setShow={setShowModal}
        title={
          Object.keys(editData).length > 0 ? "Edit Email Template" : ""
        }
        list={list.data}
        setList={setList}
        editData={editData}
        setEditData={setEditData}
      />
    </>
  );
}
