import React,{useState,useEffect} from 'react'

import { useSelector } from 'react-redux';


import ListLayout from '../containers/ListLayout'
import TableAlt from '../components/elements/table/TableAlt'
import AddEditPlans from '../components/modals/AddEditPlans'
import { postData } from '../services/api';
import { setTitle } from '../helpers/MetaTag';

import toastr from 'toastr';

import { API_URL } from '../config/host';



export default function Plans() {
  setTitle("Ryen bilpleie | Plans")
    const user = useSelector((state) => state.auth.user);

    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [keyword, setKeyword] = useState('');
    const [dateRange, setDateRange] = useState({})
    const [list, setList] = useState({
      loading: false,
      data: [],
      count: 0,
    });
    const [statusFilterData, setStatusFilterData] = useState([
      { _id: 1, name: 'Active', type: 'checkbox', value: true, isChecked: false },
      { _id: 2, name: 'Inactive', type: 'checkbox', value:false, isChecked: false}
    ])
    const [sortField, setSortField] = useState(null);
    const [sortType, setSortType] = useState(null);
  
    const [showModal, setShowModal] = useState(false)
    const [editData, setEditData] = useState({})
    const tableHeadData=[
        {
       
            width: 15,
            title: 'Title',
            name: 'title',
            align: 'left',
            sortable: true,
            filterable: false,
          },
        {
       
            width: 15,
            title: 'Description',
            name: 'description',
            align: 'left',
            sortable: true,
            filterable: false,
          },
          {
       
            width: 15,
            title: 'Status',
            name: 'status',
            align: 'left',
            sortable: true,
            filterable: true,
            filterOptions: statusFilterData,
            onFilterChange: (id) => {
           
              setStatusFilterData(data => data.map(item => item._id !== id ? item : {
                ...item,
                isChecked: !item.isChecked,
                touched:true,
              }))
            }
          },
          {
        
            action: true,
          },
    ]

    useEffect(() => {
        
          const loadData = async () => {
            setList((list) => ({ ...list, loading: true }));
            const searchParams = {}
            if (keyword) {
              searchParams.title = { $regex: keyword, $options: 'i' }
            }
            

            if(statusFilterData.filter((item)=>item.touched&&item.isChecked).length) {
                searchParams.status={$in:statusFilterData.filter((item)=>item.touched &&item.isChecked).map((item)=>item.value)}
               
            }
            if(dateRange.start && dateRange.end){
              searchParams.created_at={
                $gte:new Date(dateRange.start),
                $lte:new Date(dateRange.end)
              }
            }
    
            const res = await postData('/crud/list', {
              table_name: 'plan',
              dateRange,
              queryParams: {
                deleted_at: null,
                ...searchParams
              },
              selectParams: {
                title: true,
                status: true,
                description:true,
            
                ordering: true,
                created_at: true,
              },
              sortQuery: sortField && sortType ? { [sortField] : sortType } : { ordering: 1 },
              limit: 10,
              offset: 0,
            });
            if (res.status === 200) {
              setList((list) => ({
                ...list,
                data: res.data.list,
                loading: false,
                count: res.data.count,
              }));
            } else {
              setList((list) => ({ ...list, loading: false }));
            }
          };
    
          loadData();
     
      }, [user?._id, keyword, limit, offset, sortField, sortType, dateRange, statusFilterData.filter((item)=>item.touched&&item.isChecked).length]);

    const onDrag=()=>{}
  

    
    const actionData = (item) => [
        {
          name: 'Edit',
          icon: 'fa-pen-to-square',
          type: 'button',
          onClick: id => {
            setShowModal(true)
            setEditData(list.data.find(item => item._id === id) || {})
          }
        },
        {
          name: item.status ? 'Inactive' : 'Active',
          icon: item.status ? 'fa-circle-exclamation' : 'fa-circle-check',
          type: 'button',
          onClick: changeStatus,
        },
        {
          name: 'Delete',
          icon: 'fa-trash-can',
          type: 'button',
          actionType: 'delete',
          deleteLink: '/crud/delete',
          deletePayload: { id: [item._id], table_name: 'plan' },
        },
      ];
  
     const changeStatus = async (id) => {
        const res = await postData('/crud/statusChange', {
          table_name: 'plan',
          id: [id],
          column_name: 'status',
          column_value: !(
            list.data.find((item) => item._id === id).status || false
          ),
        });
    
        if (res.status === 200) {
          setList((list) => ({
            ...list,
            data: list.data.map((item) =>
              item._id !== id
                ? item
                : {
                    ...item,
                    status: !(
                      list.data.find((item) => item._id === id).status ||
                      false
                    ),
                    touched:true
                  }
            ),
          }));
          toastr.success(res.message)
        } else {
          toastr.error(res.message);
        }
      };
  return (
    <>
    <ListLayout
    title='Plans'
    addName='Add Plan'
    onAdd={() => setShowModal(true)}
    limit={limit}
    setLimit={setLimit}
    setKeyword={setKeyword}
    keyword={keyword}
    noOfPage={Math.ceil(list.count / limit)}
    onPageChange={(val) => setOffset(val.selected * limit)}
  >
    <TableAlt
      tableHeadData={tableHeadData}
      tableData={list.data}
      loading={list.loading}
      sortField={sortField}
      sortType={sortType}
      setSortField={setSortField}
      setSortType={setSortType}
      actionData={actionData}
      setList={setList}
      isDraggable={true}
      onDrag={onDrag}
    />
  </ListLayout>
  <AddEditPlans
    show={showModal}
    setShow={setShowModal}
    title={Object.keys(editData).length > 0 ? 'Edit Plan' : 'Add Plan'}
    list={list.data}
    setList={setList}
    editData={editData}
    setEditData={setEditData}
  />

</>
  )
}
