import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/timegrid';
import { useEffect, useState } from "react";
import { postData } from "../../services/api";
import { setTitle } from "../../helpers/MetaTag";
import AppointmentDetailsModal from "./AppointmentDetailsModal";

const FullCalender = () => {
  setTitle("Car Wash | Full Calender")
  const [events, setEvents] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [editData, seteditData] = useState({_id:''});
  
  useEffect(() => {
    const loadData = async () => {
      const res = await postData("/crud/list", {
        table_name: "appointments",
        queryParams: {
          deleted_at: null,
        },
        selectParams: {
          name: true,
          status: true,
          short: true,
          email: true,
          price: true,
          car_model: true,
          appointment_date: true,
          appointment_start_time: true,
          appointment_end_time: true,
          ordering: true,
          created_at: true,
        },
        sortQuery: { ordering: 1 },
        limit: 60,
        offset: 0,
      });

      if (res.status === 200) {
   
        let array = [];
        res.data.list.map((item, index) => {
          array.push({
  
            title: item.name,
            id: item._id,
            start: new Date(item.appointment_start_time),
          });
        });
  
        setEvents(array);
      } else {
      }
    };

    loadData();
  }, []);
  function renderEventContent(eventInfo) {
    return (
      <>
        
        <i><b>{eventInfo.event.title}</b></i>
        
      </>
    );
  }

  const eventClick = (e)=> {
    setShowModal(true);
    seteditData({_id:e.event.id})
  }
  const dateClick = (e)=> {
    console.log(e)
  }
  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin]}
        headerToolbar={{
          left: 'prev,next,today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        }}
        initialView="dayGridMonth"
        weekends={true}
        events={events}
        // editable
        // selectable
        dateClick= {dateClick}
        // navLinks={true}
        eventContent={renderEventContent}
        eventClick ={eventClick}
      />

      <AppointmentDetailsModal
          show={showModal}
          setShow={setShowModal}
          title={"View Appointment Details"}
         editData={editData}
         
        />
    </>
  );
};

export default FullCalender;
