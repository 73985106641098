import { useState, useEffect, useRef } from "react";
import AddEditLayoutModal from "../../containers/modals/AddEditLayoutModal";


import { postData } from "../../services/api";

import toastr from "toastr";
import Flatpickr from "react-flatpickr";

export default function AddEditTimeSlot({
  title = "",
  show = false,
  editData = {},
  setEditData = () => { },
  setShow = () => { },
  list = [],
  setList = () => { },
  addType = "ASC",
}) {
  const [disabled, setDisabled] = useState();
  const [timeSlot, setTimeSlot] = useState("");
  const [description, setDescription] = useState("");
  const [servings, setServings] = useState("");
  const [note, setNote] = useState("");
  const [image, setImage] = useState(null);
  const [endtime, setEndtime] = useState("");
  const [startTime, setStarttime] = useState("");
  useEffect(() => {
    if (editData?._id) {
      setStarttime(editData.start_time);
      setEndtime(editData.end_time);
    }
  }, [editData]);

  const closeModal = () => {
    setShow(false);
    setTimeSlot("");
    setDescription("");

    setNote("");
    setServings("");
    setDisabled(false);
    setEditData({});
  };
  const fp = useRef(null);

  const onSubmit = async () => {
    setDisabled(true);

    const res = await postData(`crud/${editData?._id ? "edit" : "add"}`, {
      table_name: "timeslot",

      data: {
        start_time: startTime,
        end_time: endtime,
        servings,
        description,
        note,

        id: editData?._id || null,
        ordering: editData?._id ? editData.ordering : list.length,
      },
      validationRules: {
        "data.start_time": "required",
        "data.end_time": "required",
        // 'data.ordering': 'required'
      },
      validationMessages: {
        "required.data.start_time": "Start time Field is required",
        // 'required.data.ordering': 'Ordering Field is required',
      },
    });


    if (res.status === 200) {
      if (!editData?._id) {
        if (addType !== "DESC") {
          setList((list) => ({
            ...list,
            data: [...list.data, res.data],
            count: list.count + 1,
          }));
        } else {
          setList((list) => ({
            ...list,
            data: [res.data, ...list.data],
            count: list.count + 1,
          }));
        }
      } else if (editData && editData?._id) {
        setList((list) => ({
          ...list,
          data: list.data.map((item) =>
            item._id !== editData._id ? item : res.data
          ),
        }));
      }

      closeModal();
      toastr.success(res.message);
    } else {
      setDisabled(false);
      toastr.error(res.message);
    }
  };

  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      modalSize="xl"
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
      onSubmit={onSubmit}
    >
      <div className="relative w-full">
        <div className="relative flex">
          <div className="w-1/2  mr-2">
            <Flatpickr
              ref={fp}
              className="form-control border outline-none w-full rounded-md border-slate-400 focus:outline-none active:outline-none focus:border-none active:border-none"
              placeholder="Enter Start Time"
              label="Start Time"
              options={{
                noCalendar: true,
                enableTime: true,
                dateFormat: 'H:i',
                disableMobile: true

              }}

              value={startTime ? new Date(startTime) : null}
              onChange={([e]) => {
                setStarttime(e.toISOString());
              }}
            />
          </div>
          <div className="w-1/2 ">
            <Flatpickr
              className="form-control w-full rounded-md border-slate-400 focus:outline-none active:outline-none focus:border-none active:border-none"
              placeholder="Enter End Time"
              label="End Time"
              options={{
                noCalendar: true,
                enableTime: true,
                dateFormat: 'H:i',
                disableMobile: true
              }}
              value={endtime ? new Date(endtime) : null}
              onChange={([e]) => {

                setEndtime(e.toISOString());
              }}
            />
          </div>




        </div>
      </div>
    </AddEditLayoutModal>
  );
}
