import { useEffect, useState } from "react";
import AddEditLayoutModal from "../../containers/modals/AddEditLayoutModal";
import { postData } from "../../services/api";
import Select from "../form/Select";

const AddEditAppointmentModal = ({
  title = "",
  show = false,
  editData = {},
  setEditData = () => {},
  setShow = () => {},
  
  
  list = [],
  setList = () => {},
  addType = "ASC",
}) => {
  const [disabled, setDisabled] = useState();
  const [userId, setUserId] = useState(null);
  const [carTypeId, setcarTyped] = useState(null);
  const [carTypeList, setCarTypeList] = useState([]);
  const [users, setUsers] = useState([]);
  useEffect(() => {
    if (editData?._id) {
    }
  }, [editData]);

  const closeModal = () => {
    setShow(false);
    setDisabled(false);
    setEditData({});
  };

  useEffect(() => {
    const loadUsers = async () => {
      const res = await postData("user/list", {
        type: "driver",
      });
      let company_id = [{ _id: 0, label: "All", value: 0 }];
      Array.isArray(res.data) &&
        res.data.map((item, index) => {
          company_id.push({ _id: item._id, label: item.name, value: item._id });
        });
      setUsers(company_id);
    };
    loadUsers();

    const loadCarTypes = async () => {
      const res = await postData("crud/list", {
        table_name: "carType",
        queryParams: {
          deleted_at: null,
          status: true
        },
        selectParams: {
          title: true,
          status: true
        },
      });
      let CarType_id = [{ _id: 0, label: "All", value: 0 }];
      Array.isArray(res.data.list) &&
        res.data.list.map((item, index) => {
          CarType_id.push({
            _id: item._id,
            label: item.title,
            value: item._id,
          });
        });
      setCarTypeList(CarType_id);
    };
    loadCarTypes();
  }, []);

 


  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
  
    >
      <div className="relative w-full">
        <div className="mb-3">
          <Select
            xPlacement={"left"}
            dropdownButtonClass={"!bg-white"}
            selectedValue={userId}
            dropdownData={users}
            getSelectedValue={(obj) => setUserId(obj._id)}
            label={"Select Drivers"}
          />
        </div>
        <div className="mb-3">
          <Select
            xPlacement={"left"}
            dropdownButtonClass={"!bg-white"}
            selectedValue={carTypeId}
            dropdownData={carTypeList}
            getSelectedValue={(obj) => setcarTyped(obj._id)}
            label={"Select Car Types"}
          />
        </div>
        
  
      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditAppointmentModal;
