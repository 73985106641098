import moment from 'moment/moment';
import { API_URL } from '../config/host';

export function allowedExtensions() {
  const ext = ['jpg', 'jpeg', 'png', 'pdf'];
  return ext;
}

export function allowedImageExtensions() {
  const ext = ['jpg', 'jpeg', 'png', 'svg'];
  return ext;
}

export function dayRemaining(date) {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date();
  const secondDate = new Date(date);
  const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
  return diffDays + (diffDays > 1 ? ' days' : ' day');
}

export function sumTotal(data, field) {
  let sum = 0;
  for (let i = 0; i < data.length; i++) {
    sum = sum + parseFloat(data[i][field] ? data[i][field] : 0);
  }
  return sum;
}

export function doesFileExist(file) {
  try {
    let fullPath = API_URL + file;
    var xhr = new XMLHttpRequest();
    xhr.open('HEAD', fullPath, false);
    xhr.send();
    if (xhr.readyState === 4 && xhr.status === 404) {
      return { status: 400, path: './no-data-found.jpg' };
    } else {
      return { status: 200, path: fullPath };
    }
  } catch (err) {
    return { status: 400, path: './no-data-found.jpg' };
  }
}

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const tinymceApiKey = () => {
  const key = 'hu65guu8gs1a6iw36ksmd9uo0cd0rtxjvlacpdz5w2igzf49';
  return key;
};

export function tinyMceInit() {
  const init = {
    height: 400,
    menubar: false,
    plugins: [
        'code',
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
    ],
    toolbar: 'code | undo redo | formatselect | ' +
    'bold italic backcolor | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent | ' +
    'removeformat | help ',
    //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
  }
  return init;
}

const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const convertTimezone = (date, timeZone = browserTimezone) => {
  if (!date) return '';

  return new Date(date).toLocaleString('en-US', { timeZone });
};

export const formatDate = (date, formatString) => {
  if (!date) return '';

  return moment(new Date(date)).format(formatString);
};

export const allowedImgExts = [
  'jpg',
  'jpeg',
  'png',
  'bmp',
  'gif',
  'svg',
  'webp',
  'ico',
  'jfif',
];

export const convertToBase64Async = file => new Promise((res, rej) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => res(reader.result);
  reader.onerror = error => rej(error);
})

// export const filterDataChange = (key = '', value, array = [], setArray) => {
//   const allItems = array

//   if (array.includes(value)) {

//   }
// }