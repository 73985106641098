import React, { useState, useEffect,Fragment } from "react";
import { Dialog, Transition } from '@headlessui/react';
import Button from "./form/Button";

import toastr from 'toastr';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postData } from "../services/api";
import { authLogout } from "../store/authSlice";

const LogoutPopup = ({
    isOpen,
    setIsOpen = () => {},
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLogout,setIsLogout] = useState(false);

    const closeModal = () =>{
        setIsOpen(false)
    } 

    const logout = async (e) => {
        e.preventDefault();
        setIsLogout(true);
        try {
          const verifyData = await postData("verify-token", {});
          if (verifyData.data) {
            setIsLogout(false);
            dispatch(authLogout());
            localStorage.removeItem('accessToken');
            return navigate("/signin");
          } else {
            toastr.error(verifyData.message);
            setIsLogout(false);
          }
        } catch (error) {
            toastr.error(error.message);
            setIsLogout(false);
        }
    }

    return (
        <>
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={()=>{}}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Dialog.Panel className="w-full max-w-xs transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                    >
                    Time to Go?
                    </Dialog.Title>
                    <div className="py-8 px-4">
                        <p className="text-sm text-gray-500 text-center">
                        When you are ready to end your current session, click "Logout" below.
                        </p>
                    </div>

                    <div className="flex items-center justify-center gap-4 mt-2">
                        <Button
                        buttonClasses='!bg-rose-500'
                        buttonLabelClasses='' 
                        buttonType={'button'} 
                        buttonIcon={(isLogout)?'fa-regular fa-spinner fa-spin':'fa-light fa-check'} 
                        buttonIconPosition={'left'} 
                        buttonLabel={'Logout'}
                        functions={logout}
                        buttonHasLink={false}
                        buttonDisabled={isLogout}
                        />
                        <Button 
                        buttonLabelClasses=''
                        buttonType={'button'} 
                        buttonIcon={'fa-light fa-times'} 
                        buttonIconPosition={'left'} 
                        buttonLabel={'Cancel'} 
                        buttonClasses={'!bg-slate-200 !text-slate-600 border border-slate-300'} 
                        functions={closeModal}
                        buttonHasLink={false}
                        />
                    </div>
                    </Dialog.Panel>
                </Transition.Child>
                </div>
            </div>
            </Dialog>
        </Transition>
        </>
    )
};
export default LogoutPopup;