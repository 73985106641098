import { useState, useEffect } from "react";
import Input from "../form/Input";
import AddEditLayoutModal from "../../containers/modals/AddEditLayoutModal";
import { postData } from "../../services/api";
import toastr from "toastr";

const AddEditAdminModal = ({
  title = "",
  show = false,
  editData = {},
  setEditData = () => { },
  setShow = () => { },
  list = [],
  setList = () => { },
  addType = "ASC",
}) => {
  const [disabled, setDisabled] = useState();
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    if (editData?._id) {
      setName(editData.name);
      setEmail(editData.email);
      setPhone(editData.phone);
      setLastName(editData.lastName);

    }
  }, [editData]);

  const closeModal = () => {
    setShow(false);
    setName("");
    setEmail("");
    setPhone("");
    setLastName("");
    setPassword("");
    setDisabled(false);
    setEditData({});
  };

  const onSubmit = async () => {
    setDisabled(true);

    const res = await postData(`user/${editData?._id ? "edit" : "add"}`, {

      password: editData?._id ? undefined : password,
      name: name,
      type: "admin",
      email: email,
      phone: phone,
      id: editData?._id || null,
      email_verified: 1,
      ordering: editData?._id ? editData.ordering : list.length,
    });

    if (res.status === 200) {
      if (!editData?._id) {
        if (addType !== "DESC") {
          setList((list) => ({
            ...list,
            data: [...list.data, res.data],
            count: list.count + 1,
          }));
        } else {
          setList((list) => ({
            ...list,
            data: [res.data, ...list.data],
            count: list.count + 1,
          }));
        }
      } else if (editData && editData?._id) {
        setList((list) => ({
          ...list,
          data: list.data.map((item) =>
            item._id !== editData._id ? item : res.data
          ),
        }));
      }

      closeModal();
      toastr.success(res.message);
    } else {
      setDisabled(false);
      toastr.error(res.message);
    }
  };

  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      modalSize="xl"
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
      onSubmit={onSubmit}
    >
      <div className="relative w-full">
        <div className="mb-4 ">
          <Input
            label={"Name"}
            labelClasses={"!text-xs capitalize"}
            inputType={"text"}
            inputPlaceholder={"Add Admin's name"}
            inputValue={name}
            setInput={setName}
          />
        </div>
        <div className="mb-4 ">
          <Input
            label={"Email"}
            labelClasses={"!text-xs capitalize"}
            inputType={"text"}
            inputPlaceholder={" Email"}
            inputValue={email}
            setInput={setEmail}
          />
        </div>
        <div className="mb-4 ">
          {
            editData?._id ? ""
              :
              <Input
                label={"Password"}
                labelClasses={"!text-xs capitalize"}
                inputType={"password"}
                inputPlaceholder={"Add Password"}
                inputValue={password}
                setInput={setPassword}
              />

          }
        </div>
        <div className="mb-4 ">
          <Input
            label={"Phone"}
            labelClasses={"!text-xs capitalize"}
            inputType={"text"}
            inputPlaceholder={"Add Phone no."}
            inputValue={phone}
            setInput={setPhone}
          />
        </div>
      </div>
    </AddEditLayoutModal>
  );
};

export default AddEditAdminModal;
