import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { setTitle } from "../helpers/MetaTag";
import ListLayout from "../containers/ListLayout";
import { postData } from "../services/api";
import toastr from "toastr";
// import Searching from "../components/Searching";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  // usePDF,
} from "@react-pdf/renderer";
import TableAlt from "../components/elements/table/TableAlt";
import moment from "moment";
import { API_URL } from "../config/host";
import { PDFDownloadLink } from "@react-pdf/renderer";

export default function Category() {
  setTitle("Ryen bilpleie  | Invoices");
  const user = useSelector((state) => state.auth.user);

  const companyId = new URLSearchParams(window.location.search).get(
    "company_id"
  );

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [company_id, setCompany_id] = useState(null);
  const [sitesettingData, setSitesettingData] = useState(null);
  const [userDetails, setUserData] = useState(null);
  const [dateRange, setDateRange] = useState({
    start: moment().startOf("month").format("YYYY-MM-DD"),
    end: moment().endOf("month").format("YYYY-MM-DD"),
  });
  const [invoiceData, setInvoiceData] = useState([]);
  const [invoiceId, setInvoiceId] = useState(null)
  const [isLoadingForInvoice, setIsLoadingForInvoice] = useState(false);
  const [list, setList] = useState({
    loading: false,
    data: [],
    count: 0,
  });
  const [statusFilterData] = useState([
    { _id: 1, name: "Active", type: "checkbox", value: true, isChecked: false },
    {
      _id: 2,
      name: "Inactive",
      type: "checkbox",
      value: false,
      isChecked: false,
    },
  ]);
  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      const result = await postData("site-settings/details", {});
      if (result.status === 200) {
        setSitesettingData(result.data);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      setList((list) => ({ ...list, loading: true }));
      const searchParams = {};
      if (keyword) {
        searchParams.title = { $regex: keyword, $options: "i" };
      }

      if (
        statusFilterData.filter((item) => item.touched && item.isChecked).length
      ) {
        searchParams.status = {
          $in: statusFilterData
            .filter((item) => item.touched && item.isChecked)
            .map((item) => item.value),
        };
      }

      const res = await postData("/company/invoice", {
        company_id: !company_id && companyId ? companyId : company_id,
        dateRange,
      });
      if (res.status === 200) {
        setList((list) => ({
          ...list,
          data: res.data,
          loading: false,
          count: res.data.count,
        }));
      } else {
        setList((list) => ({ ...list, loading: false }));
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user?._id,
    company_id,
    companyId,
    keyword,
    limit,
    offset,
    sortField,
    sortType,
    dateRange.start,
    dateRange.end,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    statusFilterData.filter((item) => item.touched && item.isChecked).length,
  ]);

  useEffect(() => {
    if (companyId) setCompany_id(companyId);
  }, [companyId]);

  const openPDF = async (e, id) => {
    e.preventDefault();
    setIsLoadingForInvoice(true);
    setInvoiceData([])
    setInvoiceId(id)

    await userDetailsData(id);

    const invData = await appdetails(id);

    if (
      invData.length && 
      e.target.parentElement.href
      // e.target.tagName.toLowerCase() === 'a'
    ) {
      setTimeout(() => {
        setIsLoadingForInvoice(false);
        window.open(e.target.parentElement.href);
      }, 3000);
    }
  };

  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      title: "Company Name",
      name: "company_details",
      align: "left",
      sortable: true,
      filterable: false,
      dataKey: "name",
      dataType: "object",
    },
    {
      _id: 1,
      width: 15,
      title: "Email",
      name: "company_details",
      align: "left",
      sortable: true,
      filterable: false,
      dataKey: "email",
      dataType: "object",
    },
    {
      _id: 2,
      width: 15,
      title: "Phone no",
      name: "company_details",
      align: "left",
      sortable: true,
      filterable: false,
      dataKey: "phone",
      dataType: "object",
    },
    {
      _id: 3,
      width: 15,
      title: "Appointments",
      name: "total_appointment",
      align: "left",
      sortable: false,
      filterable: false,
    },
    {
      _id: 4,
      width: 15,
      title: "Total Price",
      name: "total_amount",
      align: "left",
      sortable: false,
      filterable: false,
    },
    {
      _id: 5,
      width: 15,
      title: '',
      name: '',
      type: 'jsx',
      jsx: (item) => (
        <button className={`!bg-blue-${isLoadingForInvoice && invoiceId === item._id ? '300' : '500'} text-white p-2 rounded-md h-9 w-9`}
          disabled={isLoadingForInvoice}
          style={{ background: isLoadingForInvoice && invoiceId === item._id ? 'rgb(147, 197, 253)' : 'rgb(59, 130, 246)' }}
        >
          <span class="!text-md !font-semibold">
            <PDFDownloadLink
              fileName="somename.pdf"
              document={<MyDocument />}
            >
              {({ blob, url, loading, error }) =>
                loading ? <i className="fa-regular fa-download"></i> : <i className="fa-regular fa-download" onClick={(e) => openPDF(e, item._id, url)}></i>
              }
            </PDFDownloadLink>
          </span>
        </button>
      )
    }
  ];

  const appdetails = async (id) => {
    const res = await postData("/appointment/list", {
      company_id: id,
      dateRange,
      status: 4,
      limit: 1000,
    });
    if (res.status === 200) {
      setInvoiceData(res.data);
      return res.data;
    } else {
      return [];
    }
  };

  const userDetailsData = async (id) => {
    const res = await postData("/user/details", { id: id });
    if (res.status === 200) {
      setUserData(res.data);
    }
  };

  const changeStatus = async (id) => {
    const res = await postData("/crud/statusChange", {
      table_name: "Invoices",
      id: [id],
      column_name: "status",
      column_value: !(
        list.data.find((item) => item._id === id).status || false
      ),
    });

    if (res.status === 200) {
      setList((list) => ({
        ...list,
        data: list.data.map((item) =>
          item._id !== id
            ? item
            : {
                ...item,
                status: !(
                  list.data.find((item) => item._id === id).status || false
                ),
                touched: true,
              }
        ),
      }));
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  /* const actionData = (item) => [
    {
      name: "download invoice",
      icon: "fa-pen-to-square",
      type: "download",
      onClick: (id) => {
        appdetails(id);
      },
    },
  ]; */

  const styles = StyleSheet.create({
    body: {
      padding: "15px",
    },
    image: {
      width: "120px",
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
    },
    table: {
      display: "table",
      width: "100%",
      marginTop: 20,
      borderStyle: "solid",
      borderWidth: 1,
      borderBottom: 0,
    },
    tableRow: {
      display: "table-row",
      padding: "5px",
      textAlign: "left",
      flexDirection: "row",
      borderBottom: 1,
    },
    tableName: {
      display: "table-cell",
      width: "30%",
    },
    tableText: {
      fontSize: 10,
    },
    tableDate: {
      display: "table-cell",
      width: "20%",
    },
    tableType: {
      display: "table-cell",
      width: "20%",
    },
    tableServices: {
      display: "table-cell",
      width: "20%",
    },
    tablePrice: {
      display: "table-cell",
      width: "10%",
    },
    tableFlex: {
      display: "flex",
    },
    tableNameCell: {
      textAlign: "left",
      fontSize: 10,
    },

    tableCell: {
      fontSize: 10,
      textAlign: "left",
    },
    heading: {
      textAlign: "center",
      fontSize: 20,
      color: "black",
      marginTop: 10,
    },
    subText: {
      fontSize: 10,
      maxLines: 2,
      lineHeight: 1.4,
    },
  });

  const MyDocument = () => {
    let total = 0

    return (
      <Document>
        <Page style={styles.body}>
          <View
            style={[
              styles.tableFlex,
              { flexDirection: "row", justifyContent: "space-between" },
            ]}
          >
            <View
              style={[
                styles.infoData,
                { flexGrow: "2", width: 250, textAlign: "left", marginRight: 35 },
              ]}
            >
              <Image
                style={styles.image}
                src={`${API_URL}storage/alternate-carwash-logo.png`}
              />
            </View>
            <View
              style={[
                styles.infoData,
                { flexGrow: "2", width: 100, textAlign: "right", marginLeft: 35 },
              ]}
            >
              <Text
                style={[
                  styles.heading,
                  { textAlign: "right", fontSize: 16, marginBottom: 5 },
                ]}
              >
                {sitesettingData?.title}
              </Text>
              <Text
                style={[styles.subText, { flexWrap: "wrap", display: "flex" }]}
              >
                {sitesettingData?.address}
              </Text>
              <Text style={styles.subText}>{sitesettingData?.phone}</Text>
              <Text style={styles.subText}>{sitesettingData?.email}</Text>
            </View>
          </View>
          <View style={[styles.infoData, { marginTop: 5 }]}>
            <Text
              style={[
                styles.heading,
                { textAlign: "left", fontSize: 16, marginBottom: 5 },
              ]}
            >
              Invoice Details
            </Text>
            {/* <Text style={styles.subText}>Invoice Number: #INV2895162</Text> */}
            <Text style={styles.subText}>{userDetails?.name}</Text>
            {/* <Text style={styles.subText}>Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016</Text> */}
            <Text style={styles.subText}>{userDetails?.phone}</Text>
            <Text style={styles.subText}>{userDetails?.email}</Text>
          </View>

          <View
            style={[
              styles.tableFlex,
              {
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
                marginTop: 5,
              },
            ]}
          >
            <Text style={[styles.heading, { fontSize: 16 }]}>
              Appointment Details
            </Text>
            <Text
              style={[
                styles.subText,
                {
                  flexWrap: "wrap",
                  display: "flex",
                  fontSize: 12,
                  fontWeight: 500,
                },
              ]}
            >
              Date : {moment(dateRange.start).format("MMM Do YY")} -{" "}
              {moment(dateRange.end).format("MMM Do YY")}
            </Text>
          </View>

          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableName}>
                <Text style={styles.tableNameCell}>AppointmentID</Text>
              </View>
              <View style={styles.tableType}>
                <Text style={styles.tableCell}>Car Type</Text>
              </View>
              <View style={styles.tableServices}>
                <Text style={styles.tableCell}>Service Name</Text>
              </View>
              <View style={styles.tableDate}>
                <Text style={styles.tableCell}>Date & Time</Text>
              </View>
              <View style={styles.tablePrice}>
                <Text style={styles.tableCell}>Price</Text>
              </View>
            </View>
            {invoiceData.map((item) => {
              total = total + item.price;
              return (
                <>
                  <View style={styles.tableRow}>
                    <View style={styles.tableName}>
                      <View style={styles.tableFlex}>
                        <Text style={styles.tableText}>
                          {item.appointment_id}
                        </Text>
                        <Text style={styles.tableText}>{item.name}</Text>
                        <Text style={styles.tableText}>{item.email}</Text>
                      </View>
                    </View>
                    <View style={[styles.tableType, { textAlign: "left" }]}>
                      <Text style={styles.tableCell}>
                        {item.appointment_info?.car_type_name}
                      </Text>
                    </View>
                    <View style={styles.tableServices}>
                      <Text style={styles.tableCell}>
                        {item.appointment_info?.service_name}
                      </Text>
                    </View>
                    <View style={styles.tableDate}>
                      <Text style={styles.tableCell}>
                        {moment(item.appointment_date).format("MMM Do YY")}
                      </Text>
                      <Text style={styles.tableText}>
                        {moment(item.appointment_start_time).format("h:mm a")} -{" "}
                        {moment(item.appointment_end_time).format("h:mm a")}
                      </Text>
                    </View>
                    <View style={styles.tablePrice}>
                      <Text style={styles.tableCell}>
                        {item.price.toFixed(2)}Kr
                      </Text>
                    </View>
                  </View>
                </>
              );
            })}
            <View
              style={[
                styles.tableRow,
                {
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                },
              ]}
            >
              <Text style={styles.heading}>Total Amonut</Text>
              <Text style={styles.priceHeading}>{total ? total.toFixed(2) : 0}Kr</Text>
            </View>
          </View>
        </Page>
      </Document>
    )
  }

  return (
    <>
      <ListLayout
        title="Invoices"
        addName="Add Page"
        showAddButton={false}
        limit={limit}
        setLimit={setLimit}
        setKeyword={setKeyword}
        keyword={keyword}
        noOfPage={Math.ceil(list.count / limit)}
        onPageChange={(val) => setOffset(val.selected * limit)}
        setOffset={setOffset}
        setDateRange={setDateRange}
        setCompany_id={setCompany_id}
        showSearchButton={false}
      >
        <TableAlt
          tableHeadData={tableHeadData}
          MyDocument={MyDocument}
          tableData={list.data}
          loading={list.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
          // actionData={actionData}
          // IsActionBtn={true}
          setList={setList}
          // isDraggable={true}
          // onDrag={onDrag}
          functions={changeStatus}
          openPDF={openPDF}
        />
      </ListLayout>
      {/* {isLoadingForInvoice ? <Searching label={"Loading"} /> : null} */}
    </>
  );
}
