import { Fragment } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TableSort from "./TableSort";
import TableFilter from "./TableFilter";
import TableDateFilter from "./TableDateFilter";
import Searching from "../../Searching";
import NoDataFound from "../../NoDataFound";
import Image from "../Image";
import NoImageFound from "../../../assets/no-data-found.jpg";
import { convertTimezone, formatDate } from "../../../helpers";
import { reorder } from "../../../helpers";
// import { PDFDownloadLink } from "@react-pdf/renderer";
import TableActionAlt from "./TableActionAlt";

const TableAlt = ({
  tableData = [],
  MyDocument,
  tableHeadData = [],
  loading = false,
  IsActionBtn = false,
  sortField = "",
  functions,
  sortType = "",
  setSortField = () => {},
  setSortType = () => {},
  isDraggable = false,
  actionData = () => {},
  setList = () => {},
  onDrag = () => {},
  openPDF = () => {},
}) => {
  const onDragEnd = async (result) => {
    if (!result) return;

    const updatedList = reorder(
      tableData,
      result.source.index,
      result.destination.index
    );
    setList((list) => ({
      ...list,
      data: updatedList.map((item, i) => ({ ...item, ordering: i })),
    }));

    await onDrag(updatedList);
  };

  const status = {
    success: "bg-green-100 text-green-600",
    danger: "bg-rose-100 text-rose-600",
    warning: "bg-amber-100 text-amber-600",
    info: "bg-sky-100 text-sky-600",
    "": "bg-slate-100 text-slate-600",
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="w-full overflow-auto scroll-smooth scrollbar">
        <table className="min-w-max w-full table-auto">
          <thead>
            <tr className="bg-slate-50 text-slate-700 text-xs leading-none border-b border-slate-200">
              {/* Header items */}
              {tableHeadData.map((headItem, i) => (
                <th
                  className="py-4 px-2 text-left max-w-[240px]"
                  style={{ minWidth: 25 + "%" }}
                  key={i}
                >
                  <div className="flex items-center w-full justify-start">
                    <div className="text-[11px] uppercase font-normal text-slate-400">
                      {headItem.action ? "" : headItem.title}
                    </div>
                    {(headItem.sortable || headItem.filterable) && (
                      <div className="ml-auto flex justify-end items-center">
                        {headItem.sortable && (
                          <TableSort
                            name={headItem.name}
                            sortType={sortType}
                            sortField={sortField}
                            setSortField={setSortField}
                            setSortType={setSortType}
                          />
                        )}
                        {headItem.filterable && (
                          <TableFilter
                            xPlacement="right"
                            filterData={headItem.filterOptions || []}
                            onFilterChange={
                              headItem.onFilterChange || (() => {})
                            }
                          />
                        )}
                        {headItem.dateFilterable && (
                          <TableDateFilter
                            xPlacement="right"
                            placeholder={headItem.title}
                            onChange={headItem.onDateChange || (() => {})}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <Droppable isDropDisabled={!isDraggable} droppableId="table-body">
            {(provided) => (
              <tbody
                className="text-slate-700 text-sm font-light select-none "
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {!loading && tableData.length <= 0 && (
                  <tr className="border-b border-slate-200 w-full">
                    <td className="py-3 px-2 text-left" colSpan={20}>
                      <NoDataFound label={"No Data Found"} />
                    </td>
                  </tr>
                )}
                {loading && (
                  <tr className="border-b border-slate-200 w-full">
                    <td className="py-3 px-2 text-left" colSpan={20}>
                      <Searching label={"Searching"} />
                    </td>
                  </tr>
                )}
                {!loading && tableData.length > 0 && (
                  <>
                    {tableData.map((item, itemIndex) => (
                      <Draggable
                        key={item._id}
                        draggableId={item._id}
                        index={itemIndex}
                        isDragDisabled={!isDraggable}
                      >
                        {(provided) => (
                          <tr
                            className="border-b border-slate-200 w-full"
                            key={item._id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {tableHeadData.map((headerItem, i) =>
                              Object.keys(item).map((itemKey, key) => {
                               
                                return (
                                  <Fragment key={key}>
                                    { 
                                      headerItem.name === itemKey ? (
                                      itemKey === "status" ? (
                                        <td
                                          className="py-3 px-2 text-left max-w-[240px]"
                                          key={i}
                                          style={{ minWidth: 25 + "%" }}
                                        >
                                          <div className="flex items-center">
                                            <div
                                              className={
                                                "text-[10px] font-medium leading-tight rounded py-1 px-2 cursor-pointer " +
                                                (typeof item[itemKey] ===
                                                "boolean"
                                                  ? item[itemKey]
                                                    ? status.success
                                                    : status.warning
                                                  : typeof item[itemKey] ===
                                                    "number"
                                                  ? item[itemKey] === 1 ||
                                                    item[itemKey] === 4
                                                    ? status.success
                                                    : item[itemKey] === 2
                                                    ? status.warning
                                                    : status.danger
                                                  : "")
                                              }
                                              onClick={() => {
                                                functions(item._id);
                                              }}
                                            >
                                              {typeof item[itemKey] ===
                                              "boolean"
                                                ? item[itemKey]
                                                  ? "Active"
                                                  : "Inactive"
                                                : typeof item[itemKey] ===
                                                  "number"
                                                ? item[itemKey] === 1
                                                  ? "Approved"
                                                  : item[itemKey] === 2
                                                  ? "Pending"
                                                  : item[itemKey] === 3
                                                  ? "Cancelled"
                                                  : item[itemKey] === 4
                                                  ? "Complete"
                                                  : ""
                                                : ""}
                                            </div>
                                          </div>
                                        </td>
                                      ) : ["created_at", "updated_at"].includes(
                                          itemKey
                                        ) || itemKey.includes("date") ? (
                                        <td
                                          className="py-3 px-2 text-left max-w-[240px]"
                                          key={i}
                                          style={{ minWidth: 25 + "%" }}
                                        >
                                          <div className="text-sm font-normal text-slate-600">
                                            {formatDate(
                                              convertTimezone(item[itemKey]),
                                              "Do MMM YYYY"
                                            )}
                                          </div>
                                        </td>
                                      ) : itemKey.includes("time") ? (
                                        <td
                                          className="py-3 px-2 text-left max-w-[240px]"
                                          key={i}
                                          style={{ minWidth: 25 + "%" }}
                                        >
                                          <div className="text-sm font-normal text-slate-600">
                                            {formatDate(
                                              convertTimezone(item[itemKey]),
                                              "hh:mm A"
                                            )}
                                          </div>
                                        </td>
                                      ) : ["image", "logo"].includes(
                                          itemKey
                                        ) ? (
                                        <td
                                          className="py-3 px-2 text-left max-w-[240px]"
                                          key={i}
                                        >
                                          <div className="w-10 h-10 min-w-[40px] overflow-hidden rounded">
                                            <Image
                                              src={
                                                item[itemKey]
                                                  ? item[itemKey]
                                                  : NoImageFound
                                              }
                                              alt={"data"}
                                              width={"100%"}
                                              height={"100%"}
                                              effect={"blur"}
                                              classes={"object-cover"}
                                            />
                                          </div>
                                        </td>
                                      ) :
                                      headerItem.dataType === "object" ? (
                                        <td
                                          className="py-3 px-2 text-left max-w-[240px]"
                                          key={i}
                                          style={{ minWidth: 25 + "%" }}
                                        >
                                          <div className="text-sm font-normal text-slate-600 truncate">
                                            {!loading &&
                                            item &&
                                            item[itemKey] &&
                                            item[itemKey].hasOwnProperty(
                                              headerItem.dataKey
                                            )
                                              ? item[itemKey][
                                                  headerItem?.dataKey
                                                ]
                                              : null}
                                          </div>
                                        </td>
                                      )  : (
                                        <td
                                          className={
                                            "py-3 px-2 text-left max-w-[240px] " +
                                            (headerItem.onClick
                                              ? "cursor-pointer"
                                              : "")
                                          }
                                          key={i}
                                          style={{ minWidth: 25 + "%" }}
                                          onClick={
                                            headerItem.onClick
                                              ? () =>
                                                  headerItem.onClick(item._id)
                                              : () => {}
                                          }
                                        >
                                          <div className="text-sm font-normal text-slate-600">
                                            {headerItem.html ? (
                                              <div
                                                className="text-sm font-normal text-slate-600 truncate max-h-[60px]"
                                                dangerouslySetInnerHTML={{
                                                  __html: item[itemKey],
                                                }}
                                              ></div>
                                            ) : (
                                              item[itemKey]
                                            )}
                                          </div>
                                        </td>
                                      )
                                    ) : (
                                      ""
                                    )}

                                    {i === tableHeadData.length - 1 &&
                                      key === Object.keys(item).length - 1 &&
                                      actionData(item)?.length > 0 && (
                                        <td
                                          className="py-3 px-2 text-left max-w-[240px]"
                                          style={{ minWidth: 25 + "%" }}
                                        >
                                          <div className="flex items-center justify-end w-[100px]">
                                            <TableActionAlt
                                              dropdownData={actionData(item)}
                                              xPlacement="right"
                                              MyDocument={MyDocument}
                                              itemId={item._id}
                                              setList={setList}
                                              openPDF={openPDF}
                                              IsActionBtn={IsActionBtn}
                                            />
                                            {/* {IsActionBtn ? (
                                              <button
                                                type="button"
                                                className="flex justify-center items-center gap-2 bg-sky-500 text-white rounded text-lg h-10 py-0 px-3 transition-all duration-200 cursor-pointer !w-full  hover:!bg-slate-100"
                                                onClick={(e) =>
                                                  openPDF(e, item._id)
                                                }
                                              >
                                                <PDFDownloadLink
                                                  fileName="somename.pdf"
                                                  document={<MyDocument />}
                                                >
                                                  {({
                                                    blob,
                                                    url,
                                                    loading,
                                                    error,
                                                  }) =>
                                                    loading
                                                      ? "Loading document..."
                                                      : "ghg"
                                                  }
                                                  Download
                                                </PDFDownloadLink>
                                              </button>
                                            ) : } */}
                                          </div>
                                        </td>
                                      )}
                                    {
                                      (headerItem.type === 'jsx' && key === Object.keys(item).length - 1) ?
                                      <td>
                                        { headerItem.jsx(item) }
                                      </td> : ''
                                    }
                                  </Fragment>
                                );
                              })
                            )}
                          </tr>
                        )}
                      </Draggable>
                    ))}
                  </>
                )}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </table>
      </div>
    </DragDropContext>
  );
};

export default TableAlt;

// {
//   headerItem.action &&
//   <td className='py-3 px-2 text-left max-w-[240px]'>
//     <div className="flex items-center justify-end">
//       <TableActionAlt
//         dropdownData={actionData}
//         xPlacement="right"
//         itemId={item._id}
//       />
//     </div>
//   </td>
// }
