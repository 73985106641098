import { type } from "@testing-library/user-event/dist/type";
import axios from "axios";
import BASE_URL from "../config/host";

export const postData = async (url, data,contentType="application/json") => {
  try {
    const instance = axios.create({
      baseURL: BASE_URL,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        'content-type':contentType
      },
      
    });

    const res = await instance.post(url, data);
    return res.data;
  } catch (error) {
    return { status: 400, message: error.message };
  }
};
