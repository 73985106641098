import React from 'react'

const Header = () => {
    return (
        <div className="w-full">
            <div className="text-left font-semibold text-sm 2xl:text-base text-neutral-40 flex p-3 pb-0 border-b border-neutral-20/30">
                <div className="px-1 md:px-0 py-4 w-1/4 min-w-[120px] flex items-center">
                    <div className="text-[11px] uppercase font-normal text-slate-400">
                        CAR TYPES
                    </div>
                </div>
                <div className="px-1 md:px-0 py-4 w-1/4 min-w-[120px] flex items-center">
                    <div className="text-[11px] uppercase font-normal text-slate-400">
                        STATUS
                    </div>
                </div>
                <div className="px-1 md:px-0 py-4 w-1/4 min-w-[120px] flex items-center">
                    <div className="text-[11px] uppercase font-normal text-slate-400">
                        CREATED AT
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header