import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { setTitle } from "../helpers/MetaTag";

import { postData } from "../services/api";

import toastr from "toastr";

import TableAlt from "../components/elements/table/TableAlt";

import ListLayout from "../containers/ListLayout";

import { useNavigate } from "react-router";
import AppointmentStatusModal from "../components/modals/AppointmentStatusModal";
import AppointmentDetailsModal from "../components/modals/AppointmentDetailsModal";

export default function Category() {
  setTitle("Ryen bilpleie  | Dashboard");
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const searchParams = new URLSearchParams(window.location.search);
  const selctedCompanyId = searchParams ? searchParams.get("company_id") : 0;
  const selctedTypeId = searchParams ? searchParams.get("car_type_id") : 0;
  const selctedDriverId = searchParams ? searchParams.get("driver_id") : 0;
  const selctedServiceId = searchParams ? searchParams.get("service_id") : 0;
  const [limit, setLimit] = useState(10);
  const[companyLimit,setCompanyLimit]=useState(10)
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [dateRange, setDateRange] = useState({});
  const [showModal, setShowModal] = useState(false);
 
  const [showStatusModal, setShowStatusModal] = useState(false);


  const [list, setList] = useState({
    loading: false,
    data: [],
    count: 0,
  });
  const [companyList,setCompanyList]=useState({
    loading:false,
    data:[],
    count:0

  })
  const [statusFilterData, setStatusFilterData] = useState([
    { _id: 1, name: "Approved", type: "checkbox", value: 1, isChecked: false },
    {
      _id: 2,
      name: "Pending",
      type: "checkbox",
      value: 2,
      isChecked: false,
    },
    {
      _id: 3,
      name: "Canceled",
      type: "checkbox",
      value: 3,
      isChecked: false,
    },
  ]);
  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [companyId, setCompanyId] = useState(selctedCompanyId);
  const [driverId, setDriverId] = useState(selctedDriverId);
  const [cartTypeId, setCartTypeId] = useState(selctedTypeId);
  const [carServiceId, setCarServiceId] = useState(selctedServiceId);
  const [editData, setEditData] = useState({});



  useEffect(() => {
    const loadData = async () => {
      setList((list) => ({ ...list, loading: true }));
      const searchParams = {};
      if (keyword) {
        searchParams.name = { $regex: keyword, $options: "i" };
      }

      if (
        statusFilterData.filter((item) => item.touched && item.isChecked).length
      ) {
        searchParams.status = {
          $in: statusFilterData
            .filter((item) => item.touched && item.isChecked)
            .map((item) => item.value),
        };
      }
      if (dateRange.start && dateRange.end) {
        searchParams.appointment_date = {
          $gte: new Date(dateRange.start),
          $lte: new Date(dateRange.end),
        };
      }

      const res = await postData("/crud/list", {
        table_name: "appointments",

        dateRange,
        queryParams: {
          ...searchParams,
        },
        selectParams: {
          name: true,
          status: true,
          short: true,
          email: true,
          price: true,
          car_model: true,
          appointment_date: true,
          appointment_start_time: true,
          appointment_end_time: true,
          ordering: true,
          created_at: true,
          serviceInfo: {
            $cond:[
              {$arrayElemAt:["$serviceInfo",0]},
              {$arrayElemAt:["$serviceInfo",0]},
              null
            ]
          },
          carTypeInfo: [
            {$arrayElemAt:["$carTypeInfo",0]},
            {$arrayElemAt:["$carTypeInfo",0]},
            null
          ],
        },
        join: [
          {
            toTable: "services",
            fromField: "service_id",
            toField: "_id",
            selectField: "serviceInfo",
            justOne: true,
            projection: {
              name: true,
            },
          },
          {
            toTable: "cartypes",
            fromField: "car_type_id",
            toField: "_id",
            selectField: "carTypeInfo",
            justOne: true,
            projection: {
              title: true,
            },
          },
        ],
        sortQuery:
          sortField && sortType ? { [sortField]: sortType } : { created_at: -1 },
        limit: 10,
        offset: 0,
      });
      if (res.status === 200) {
        setList((list) => ({
          ...list,
          data: res.data.list,
          loading: false,
          count: res.data.count,
        }));
      } else {
        setList((list) => ({ ...list, loading: false }));
      }
    };

    loadData();
  }, [
    user?._id,
    keyword,
    limit,
    offset,
    sortField,
    sortType,
    dateRange,
    cartTypeId,
    carServiceId,
    companyId,
    driverId,
    statusFilterData.filter((item) => item.touched && item.isChecked).length,
  ]);

  const tableHeadData = [
    {
      _id: 1,
      width: 15,
      title: "name",
      name: "name",
      align: "left",
      onClick:(id)=>{
        setShowModal(true);
        setEditData(list.data.find((item) => item._id === id) || {});
       
      },
  
      filterable: false,
    },
    {
      _id: 2,
      width: 15,
      title: "email",
      name: "email",
      align: "left",
      onClick:(id)=>{
        console.log(editData,"editdataclick")
        setShowModal(true);
        setEditData(list.data.find((item) => item._id === id) || {});
       
      },
 
      filterable: false,
    },
    {
      _id: 3,
      width: 15,
      title: "status",
      name: "status",
      align: "center",
      sortable: true,
      filterable: true,
      filterOptions: statusFilterData,
      onFilterChange: (id) => {
        setStatusFilterData((data) =>
          data.map((item) =>
            item._id !== id
              ? item
              : {
                  ...item,
                  isChecked: !item.isChecked,
                  touched: true,
                }
          )
        );
      },
    },
    // {
    //   _id:8,
    //   width:15,
    //   title:"Car Type",
    //   name:"carTypeInfo",
    //   align:"left",
    //   dataType:"object",
    //   dataKey:"title",
    //   filterable:false

    // },
    {
      _id:9,
      width:15,
      title:"Service Type",
      name:"serviceInfo",
      dataType:"object",
      dataKey:"name",
      align:"left",
      sortable:false,
      filterable:false
    },

    {
      _id: 4,
      width: 15,
      title: "Car model",
      name: "car_model",
      align: "left",
   
      filterable: false,
    },

    {
      _id: 5,
      width: 15,
      title: "Price",
      name: "price",
      align: "left",
 
      filterable: false,
    },

    {
      _id: 6,
      width: 15,
      title: "Appointment Date",
      name: "appointment_date",
      align: "left",
      sortable: true,
      dateFilterable: true,
      onDateChange: (e) => {
        if (e && e.length && e.length > 1) {
          setDateRange({
            start: e[0],
            end: e[1],
          });
        }
      },
    },
    {
      _id: 7,
      width: 15,
      title: "Start time",
      name: "appointment_start_time",
      align: "left",
     
      filterable: false,
    },
    {
      _id: 8,
      width: 15,
      title: "End time",
      name: "appointment_end_time",
      align: "left",
    
      filterable: false,
    },


  ];

  const HandlechangeStatus = async (id, statusVal) => {
    console.log(id)
    const res = await postData("/crud/statusChange", {
      table_name: "appointments",
      id: [id],
      column_name: "status",

      column_value: statusVal
    });

    console.log(res)

    if (res.status === 200) {
      setList((list) => ({
        ...list,
        data: list.data.map((item) =>
          item._id !== id
            ? item
            : {
                ...item,
                status: statusVal,
                touched: true,
              }
        ),
        
      }));
      toastr.success(res.message)
    } else {
      toastr.error(res.message);
    }
  };



  // ];

  const handelReset = () => {};

  // For Recent companies List

  useEffect(()=>{
    if(user._id){
      console.log(user._id,"userId")

      const loadCompaniesData=async()=>{
        setCompanyList((companyList) => ({ ...companyList, loading: true }));
        const searchParams={}
        if (keyword) {
          searchParams.name = { $regex: keyword, $options: "i" };
        }
        const res = await postData("user/list", {
          type: "company",
          keyword: keyword,
          limit: limit,
          offset: offset,
          sortQuery: sortField && sortType ? { [sortField]: sortType } : { ordering: 1 },
        });
        if(res.status===200){
          setCompanyList((companyList) => ({
            ...companyList,
            data: res.data,
            loading: false,
            count: res.count,
          }));
        }else {
          setCompanyList((companyList) => ({ ...companyList, loading: false }));
        }
  
  
  
  
      }
      loadCompaniesData()
   
    }
  
  

  },[user._id])

  const companiesData=[
    {
      _id: 1,
      width: 15,
      title: "Name",
      name: "name",
      align: "left",

   
      filterable: false,
    },
    {
      _id: 2,
      width: 15,
      title: "email",
      name: "email",
      align: "left",
   
      filterable: false,
    },
    {
      _id: 3,
      width: 15,
      title: "phone",
      name: "phone",
      align: "left",
     
      filterable: false,
    },

    {
      _id: 4,
      width: 15,
      title: "Created At",
      name: "created_at",
      align: "left",
      sortable: true,
      filterable: false,
    },

  ]
  
  function openModal(id) {
    setShowStatusModal(true);
    setEditData(list.data.find((item) => item._id === id) || {});
  }





  return (
    <>
     <div className="flex gap-2">

      <ListLayout
        title="Recent Appointments"
        width="w-1/2"
        onClick={() => handelReset()}
        showAddButton={false}
        
        showLimitButton={false}
        showSearchButton={false}
        limit={limit}
        setLimit={setLimit}
        setKeyword={setKeyword}
        setCompanyId={setCompanyId}
        setDriverId={setDriverId}
        cartTypeId={cartTypeId}
        carServiceId={carServiceId}
        setCarServiceId={setCarServiceId}
        setCartTypeId={setCartTypeId}
        driverId={driverId}
        keyword={keyword}
        companyId={companyId}

      >
        <TableAlt
          tableHeadData={tableHeadData}
          tableData={list.data}
          loading={list.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
    
          setList={setList}
          isDraggable={false}
   functions={openModal}
         
        />
      </ListLayout>
      <ListLayout
        title="Recent Companies"
        width="w-1/2"
        onClick={() => handelReset()}
        showAddButton={false}
        showLimitButton={false}
        showSearchButton={false}
        limit={companyLimit}
        setLimit={setCompanyLimit}
        setKeyword={setKeyword}
        setCompanyId={setCompanyId}
        setDriverId={setDriverId}
        cartTypeId={cartTypeId}
        carServiceId={carServiceId}
        setCarServiceId={setCarServiceId}
        setCartTypeId={setCartTypeId}
        driverId={driverId}
        keyword={keyword}
        companyId={companyId}

      >
        <TableAlt
          tableHeadData={companiesData}
          tableData={companyList.data}
          loading={companyList.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}

          setList={setCompanyList}
          isDraggable={false}
     

        />
      </ListLayout>
     </div>


     <AppointmentStatusModal
        show={showStatusModal}
        setShow={setShowStatusModal}
        title={"View Appointment Status"}
        list={list.data}
        setList={setList}
        editData={editData}
        setEditData={setEditData}
        HandlechangeStatus={HandlechangeStatus}
      />
       <AppointmentDetailsModal
        show={showModal}
        setShow={setShowModal}
        title={"View Appointment Details"}
        list={list.data}
        setList={setList}
        editData={editData}
        setEditData={setEditData}
      />

    </>
  );
}

