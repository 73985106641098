import { useState, useEffect } from "react";
import Input from "../form/Input";
import AddEditLayoutModal from "../../containers/modals/AddEditLayoutModal";
import { postData } from "../../services/api";
import toastr from "toastr";
import Select from "../form/Select";

const AddEditDriversModal = ({
  title = "",
  show = false,
  editData = {},
  setEditData = () => { },
  setShow = () => { },
  list = [],
  setList = () => { },
  addType = "ASC",
}) => {
  const [disabled, setDisabled] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [userId, setUserId] = useState("");
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (editData?._id) {
      setName(editData.name);
      setEmail(editData.email);
      setPhone(editData.phone);
      setUserId(editData?.company_id?._id ? editData?.company_id?._id : editData?.company_id);
    }
    console.log(editData);
  }, [editData]);

  const closeModal = () => {
    setShow(false);
    setName("");
    setEmail("");
    setPhone("");
    setPassword("");
    setDisabled(false);
    setEditData({});
  };

  const onSubmit = async () => {
    setDisabled(true);

    const res = await postData(`user/${editData?._id ? "edit" : "add"}`, {
      password: password,
      name: name,
      email: email,
      phone: phone,
      type: "driver",
      company_id: userId,
      id: editData?._id || null,
      ordering: editData?._id ? editData.ordering : list.length,
    });

    if (res.status === 200) {
      if (!editData?._id) {
        if (addType !== "DESC") {
          setList((list) => ({
            ...list,
            data: [...list.data, res.data],
            count: list.count + 1,
          }));
        } else {
          setList((list) => ({
            ...list,
            data: [res.data, ...list.data],
            count: list.count + 1,
          }));
        }
      } else if (editData && editData?._id) {
        setList((list) => ({
          ...list,
          data: list.data.map((item) =>
            item._id !== editData._id ? item : res.data
          ),
        }));
      }

      closeModal();
      toastr.success(res.message);
    } else {
      setDisabled(false);
      toastr.error(res.message);
    }
  };

  useEffect(() => {
    const loadUsers = async () => {
      const res = await postData("user/list", {
        type: "company",
      });
      let company_id = [{ _id: 0, label: "All", value: 0 }];
      Array.isArray(res.data) &&
        res.data.map((item, index) => {
          company_id.push({ _id: item._id, label: item.name, value: item._id });
        });
      setUsers(company_id);
    };
    loadUsers();
  }, []);

  console.log(userId);
  console.log (editData,"editdata")

  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      modalSize="xl"
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
      onSubmit={onSubmit}
    >
      <div className="relative w-full">
        <div className="mb-3">
          <Input
            label={"Name"}
            labelClasses={"!text-xs capitalize"}
            inputType={"text"}
            inputPlaceholder={"Add Drivers's name"}
            inputValue={name}
            setInput={setName}
          />
        </div>
        <div className="mb-3">
          <Input
            label={"Email"}
            labelClasses={"!text-xs capitalize"}
            inputType={"text"}
            inputPlaceholder={"Add Email"}
            inputValue={email}
            setInput={setEmail}
          />
        </div>
        <div className="mb-3">
          {/* {editData?._id ?  ( */}
            <Input
              label={"Password"}
              labelClasses={"!text-xs capitalize"}
              inputType={"password"}
              inputPlaceholder={"Enter Password"}
              inputValue={password}
              setInput={setPassword}
            />
          {/* } */}
        </div>
        <div className="mb-3">
          <Select
            xPlacement={"left"}
            dropdownButtonClass={"!bg-white"}
            selectedValue={userId}
            dropdownData={users}
            getSelectedValue={(obj) => setUserId(obj._id)}
            label={"Select Company"}
          />
        </div>

        <Input
          label={"Phone"}
          labelClasses={"!text-xs capitalize"}
          inputType={"text"}
          inputPlaceholder={"Add Phone no."}
          inputValue={phone}
          setInput={setPhone}
        />
      </div>
    </AddEditLayoutModal >
  );
};

export default AddEditDriversModal;
