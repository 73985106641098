import moment from "moment";
import { useState, useEffect } from "react";
import AddEditLayoutModal from "../../containers/modals/AddEditLayoutModal";
import { postData } from "../../services/api";


const AppointmentDetailsModal = ({
  title = "",
  show = false,
  editData = {},
  setEditData = () => { },
  setShow = () => { },
  list = [],
  setList = () => { },
  addType = "ASC",
}) => {
  const [disabled, setDisabled] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [appointDate, setAppointDate] = useState("");
  const [appointStart, setAppointStart] = useState("");
  const [appointEnd, setAppointEnd] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    if (editData?._id) {
      setName(editData.name);
      setEmail(editData.email);
      setAppointDate(editData.appointment_date);
      setAppointStart(editData.appointment_end_time);
      setAppointEnd(editData.appointment_end_time);
    }
  }, [editData]);

  const closeModal = () => {
    setShow(false);
    setName("");
    setEmail("");
    setAppointDate("");
    setAppointStart("");
    setAppointEnd("");
    setDisabled(false);
    setEditData({});
  };

  useEffect(() => {
    if (editData?._id) {
      const loadData = async () => {
        const res = await postData(`/crud/list`, {
          table_name: "appointments",
          queryParams: { _id: editData?._id },
          selectParams: {
            name: true,
            status: true,
            short: true,
            email: true,
            price:{
              $concat: [
                { $toString: '$price' },
                'Kr'
              ]
            },
            car_model: true,
            appointment_date: true,
            appointment_start_time: true,
            appointment_end_time: true,
            ordering: true,
            created_at: true,
            serviceInfo: true,
            carTypeInfo: true,
            comment: true,
            driver_id: true,
            appointment_id: {
              $cond: [
                '$appointment_id', '$appointment_id', null
              ]
            },
            company_name: {
              $cond: [
                { $arrayElemAt: [ '$company_details.name', 0 ] },
                { $arrayElemAt: [ '$company_details.name', 0 ] },
                null
              ]
            },
            company_email: {
              $cond: [
                { $arrayElemAt: [ '$company_details.email', 0 ] },
                { $arrayElemAt: [ '$company_details.email', 0 ] },
                null
              ]
            },

          },
          join: [
            {
              toTable: "services",
              fromField: "service_id",
              toField: "_id",
              selectField: "serviceInfo",
              justOne: true,
              projection: {
                name: true,
              },
            },
            {
              toTable: "cartypes",
              fromField: "car_type_id",
              toField: "_id",
              selectField: "carTypeInfo",
              justOne: true,
              projection: {
                title: true,
              },
            },
            {
              toTable: 'users',
              fromField: 'company_id',
              toField: '_id',
              selectField: 'company_details',
              projection: {
                name: true, email: true
              }
            },

          ],
        });
        if (res.data.list) {
          setData(res.data.list[0]);
        }
      };
      loadData();
    }
  }, [editData]);

  return (
    <AddEditLayoutModal
      isBtn={false}
      title={title}
      show={show}
      modalSize="xl"
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
    >
      <div className="relative w-full">
        <div className="rounded-lg overflow-hidden border-slate-200 border">
          <div className="p-4">
            {/* <div className="font-bold text-xl mb-3">{data.name}</div> */}
            <div className="text-gray-700 grid divide-y divide-slate-200">

              {/* {data.appointment_date} */}
              <div className="flex items-start justify-between py-2 gap-2">
                <div className="flex items-center text-slate-900 gap-2 w-40 min-w-[160px]">
                  <i class="fa-sharp fa-solid fa-hashtag text-sky-500"></i>
                  <div className="text-sm font-medium"> Id</div>
                </div>
                <div className="text-sm text-slate-500">{data.appointment_id}</div>
              </div>
              <div className="flex items-start justify-between py-2 gap-2">
                <div className="flex items-center text-slate-900 gap-2 w-40 min-w-[160px]">
                  <i class="fa-regular fa-fw fa-building text-sky-500"></i>
                  <div className="text-sm font-medium">Company Name</div>
                </div>
                <div className="text-sm text-slate-500">{data.company_name}</div>
              </div>
              <div className="flex items-start justify-between py-2 gap-2">
                <div className="flex items-center text-slate-900 gap-2 w-40 min-w-[160px]">
                  <i class="fa-regular fa-fw fa-envelope text-sky-500"></i>
                  <div className="text-sm font-medium">Company Email</div>
                </div>
                <div className="text-sm text-slate-500">{data.company_email}</div>
              </div>
              <div className="flex items-start justify-between py-2 gap-2">
                <div className="flex items-center text-slate-900 gap-2 w-40 min-w-[160px]">
                  <i class="fa-regular fa-fw fa-id-card text-sky-500"></i>
                  <div className="text-sm font-medium">Driver Name</div>
                </div>
                <div className="text-sm text-slate-500">{data.driver_id !==null?data.name:"N/A"}</div>
              </div>
              <div className="flex items-start justify-between py-2 gap-2">
                <div className="flex items-center text-slate-900 gap-2 w-40 min-w-[160px]">
                  <i class="fa-regular fa-fw fa-envelope text-sky-500"></i>
                  <div className="text-sm font-medium">Driver Email</div>
                </div>
                <div className="text-sm text-slate-500">{data.driver_id !==null?data.email:"N/A"}</div>
              </div>

              <div className="flex items-start justify-between py-2 gap-2">
                <div className="flex items-center text-slate-900 gap-2 w-40 min-w-[160px]">
                  <i class="fa-regular fa-fw fa-lightbulb-on text-sky-500"></i>
                  <div className="text-sm font-medium">Status</div>
                </div>
                <div className="text-sm text-slate-500">{data.status === 1 ? "Approved" : data.status === 2 ? "Pending" : data.status === 3 ? "Cancelled" : "Complete"} </div>
              </div>

              <div className="flex items-start justify-between py-2 gap-2">
                <div className="flex items-center text-slate-900 gap-2 w-40 min-w-[160px]">
                  <i class="fa-regular fa-fw fa-cars text-sky-500"></i>
                  <div className="text-sm font-medium">Model Name</div>
                </div>
                <div className="text-sm text-slate-500">{data.car_model}</div>
              </div>
              <div className="flex items-start justify-between py-2 gap-2">
                <div className="flex items-center text-slate-900 gap-2 w-40 min-w-[160px]">
                  <i class="fa-regular fa-fw fa-car text-sky-500"></i>
                  <div className="text-sm font-medium">Car Type</div>
                </div>
                {data && Array.isArray(data?.carTypeInfo) && (
                <div className="text-sm text-slate-500">{data?.carTypeInfo[0]?.title}</div>
                )}
              </div>
              <div className="flex items-start justify-between py-2 gap-2">
                <div className="flex items-center text-slate-900 gap-2 w-40 min-w-[160px]">
                  <i class="fa-regular fa-fw fa-car-mechanic text-sky-500"></i>
                  <div className="text-sm font-medium">Service Type</div>
                </div>
                <div className="text-sm text-slate-500">
                  {data &&
                    Array.isArray(data?.serviceInfo) &&
                    data?.serviceInfo[0]?.name}
                </div>
              </div>

              <div className="flex items-start justify-between py-2 gap-2">
                <div className="flex items-center text-slate-900 gap-2 w-40 min-w-[160px]">
                  <i class="fa-regular fa-fw fa-calendar-alt text-sky-500"></i>
                  <div className="text-sm font-medium">Date</div>
                </div>
                <div className="text-sm text-slate-500"> {moment(data.appointment_date).format("Do MMM, YYYY")}</div>
              </div>

              <div className="flex items-start justify-between py-2 gap-2">
                <div className="flex items-center text-slate-900 gap-2 w-40 min-w-[160px]">
                  <i class="fa-regular fa-fw fa-clock text-sky-500"></i>
                  <div className="text-sm font-medium">Time</div>
                </div>
                <div className="text-sm text-slate-500 flex items-center gap-1">
                  <span>{moment(data.appointment_start_time).format("h:mm a")}</span>
                  <i class="fa-regular fa-fw fa-minus"></i>
                  <span>{moment(data.appointment_end_time).format("h:mm a")}</span>
                  </div>
              </div>

              <div className="flex items-start justify-between py-2 gap-2">
                <div className="flex items-center text-slate-900 gap-2 w-40 min-w-[160px]">
                  <i class="fa-regular fa-fw fa-usd-circle text-sky-500"></i>
                  <div className="text-sm font-medium">Price</div>
                </div>
                <div className="text-sm text-slate-500">{data.price}</div>
              </div>
              <div className="flex items-start justify-between py-2 gap-2">
                <div className="flex items-center text-slate-900 gap-2 w-40 min-w-[160px]">
                  <i class="fa-regular fa-fw  fa-comments text-sky-500"></i>
                  <div className="text-sm font-medium">Comment</div>
                </div>
                <div className="text-sm text-slate-500">{data.comment}</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </AddEditLayoutModal>
  );
};

export default AppointmentDetailsModal;
