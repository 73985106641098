import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from '../components/form/Button';

const ModalContainer = ({
  show = false,
  title = '',
  closeModal = () => {},
  modalSize = '',
  children,
}) => {
  const modalSizes = {
    '': 'max-w-xs',
    xs: 'max-w-xs',
    s: 'max-w-sm',
    m: 'max-w-md',
    l: 'max-w-lg',
    xl: 'max-w-xl',
    xxl: 'max-w-2xl',
    xxxl: 'max-w-3xl',
    xxxxl: 'max-w-4xl',
    xxxxxl: 'max-w-5xl',
    xxxxxxl: 'max-w-6xl',
    xxxxxxxl: 'max-w-7xl',
  }

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as='div' className='relative z-50' onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>
        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className={`w-full ${modalSizes[modalSize]} transform  rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all`}>
              <Button
                  buttonClasses={"!absolute !top-1 !right-1 !p-0 !w-10 !h-10 !bg-transparent !text-slate-400 !text-2xl hover:!text-rose-500"} 
                  buttonType={"button"} 
                  buttonIcon={"fa-light fa-xmark"} 
                  buttonIconPosition={"left"}
                  buttonHasLink={false}
                  functions={closeModal}
                />
                <Dialog.Title
                  as='h3'
                  className='text-lg font-medium leading-6 text-gray-900 text-center'
                >
                  {title}
                </Dialog.Title>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalContainer;
