import ReactPaginate from "react-paginate";
import Button from "../components/form/Button";
import Select from "../components/form/Select";
import Search from "../components/form/Search";
import { useEffect, useState, useRef } from "react";
import { postData } from "../services/api";
import { Page, Text, View, Document, PDFDownloadLink } from "@react-pdf/renderer";
import Flatpickr from 'react-flatpickr';
import moment from "moment/moment";


const ListLayout = ({
  children,
  MyDocument,
  title,
  styles,
  showAddButton = true,
  showLimitButton = true,
  totalCount = 0,
  showSearchButton = true,
  setOffset = () => { },
  width = "",
  ...props
}) => {
  const flatPickr = useRef(null);
  const [resetValue, setResetValue] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [companyList, setCompanyList] = useState([
    { _id: 0, label: "All", value: 0 },
  ]);
  const [driverList, setDriverList] = useState([
    { _id: 0, label: "All", value: 0 },
  ]);
  const [carTypeList, setCarTypeList] = useState([
    { _id: 0, label: "All", value: 0 },
  ]);
  const [carServiceList, setCarServiceList] = useState([
    { _id: 0, label: "All", value: 0 },
  ]);
  const dropdownReset = [
    companyList[0],
    driverList[0],
    carTypeList[0],
    carServiceList[0],
  ];

  const searchParams = new URLSearchParams(window.location.search).get(
    "company_id"
  );
  const searchCarTypes = new URLSearchParams(window.location.search).get(
    "car_type_id"
  );
  const searchDrivers = new URLSearchParams(window.location.search).get(
    "driver_id"
  );
  const searchServices = new URLSearchParams(window.location.search).get(
    "service_id"
  );
  const selctedCompanyId = searchParams ? searchParams : 0;
  const selectedTypeId = searchCarTypes ? searchCarTypes : 0;
  const selectedDriverId = searchDrivers ? searchDrivers : 0;
  const selectedServiceId = searchServices ? searchServices : 0;





  const createPDF = async () => {
    const res = await postData("pdf-export", {
      company_id: props.companyId,
      driver_id: props.driverId,
      car_type_id: props.cartTypeId,
      service_id: props.carServiceId,
    });

    window.open(res);
  };

  const openLinkInNewTab = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href);
    }
  };

  useEffect(() => {
    const SelectCompany = async () => {
      const res = await postData("user/list", {
        type: "company",
        limit: 1000
      });
      let company_id = [{ _id: 0, label: "All", value: 0 }];
      Array.isArray(res.data) &&
        res.data.map((item, index) => {
          company_id.push({
            _id: item._id,
            label: item.name,
            value: item._id,
          });
        });

      setCompanyList(company_id);
    };
    SelectCompany();
  }, []);

  useEffect(() => {
    const SelectDriver = async () => {
      const res = await postData("user/list", {
        type: "driver",
        limit: 1000
      });
      let driver_id = [{ _id: 0, label: "All", value: 0 }];
      Array.isArray(res.data) &&
        res.data.map((item, index) => {
          driver_id.push({
            _id: item._id,
            label: item.name,
            value: item._id,
          });
        });
      setDriverList(driver_id);
    };
    SelectDriver();
  }, []);

  useEffect(() => {
    const SelectCarType = async () => {
      const res = await postData("crud/list", {
        table_name: "carType",
        queryParams: {
          deleted_at: null,
        },
        selectParams: {
          title: true,
          status: true,
          ordering: true,
          created_at: true,
        },
        limit: 10,
        offset: 0,
      });
      let CarType_id = [{ _id: 0, label: "All", value: 0 }];
      Array.isArray(res.data.list) &&
        res.data.list.map((item, index) => {
          CarType_id.push({
            _id: item._id,
            label: item.title,
            value: item._id,
          });
        });
      setCarTypeList(CarType_id);
    };
    SelectCarType();
  }, []);

  useEffect(() => {
    const SelectCarService = async () => {
      const res = await postData("crud/list", {
        table_name: "services",
        queryParams: {
          deleted_at: null,
        },
        selectParams: {
          name: true,
          status: true,
        },
      });
      let CarService_id = [{ _id: 0, label: "All", value: 0 }];
      Array.isArray(res.data.list) &&
        res.data.list.map((item, index) => {
          CarService_id.push({
            _id: item._id,
            label: item.name,
            value: item._id,
          });
        });
      setCarServiceList(CarService_id);
    };
    SelectCarService();
  }, []);

  const handelReset = () => {
    setResetValue(true);
    props.setCompanyId(0);
    props.setDriverId(0);
    props.setCartTypeId(0);
    props.setCarServiceId(0);
  };

  const handleSelect = (value, type) => {
    if (type === "company") {
      props.setCompanyId(value);
    }

    if (type === "driver") {
      props.setDriverId(value);
    }

    if (type === "cartype") {
      props.setCartTypeId(value);
    }

    if (type === "service") {
      props.setCarServiceId(value);
    }

    setResetValue(false);
  };

  const PrintDoc = ({ values }) => (
    <Document>
      <Page size="A6" style={styles.page}>
        <View style={styles.section}>
          <Text>Section #1</Text>
        </View>
      </Page>
    </Document>
  );

  const openPDF = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className={width}>
      <div className="relative flex justify-between items-center mb-4">
        <div className="text-3xl text-slate-800 font-bold">{title}</div>
        <div className="">
          {showAddButton ? (
            <Button
              buttonType={"button"}
              buttonIcon={"fa-light fa-plus"}
              buttonIconPosition={"left"}
              buttonLabel={props.addName}
              buttonHasLink={props.addLink ? true : false}
              buttonLink={props.addLink || ""}
              functions={props.onAdd || (() => { })}
            />
          ) : null}
        </div>
      </div>
      <div className="relative flex justify-between items-center mb-4">
        <div className="relative">
          {showLimitButton ? (
            <Select
              xPlacement={"left"}
              dropdownButtonClass={"!bg-white"}
              selectedValue={props.limit}
              label={"Select Items"}
              dropdownData={[
                {
                  _id: totalCount || 1000000,
                  label: "All Items",
                  value: totalCount || 1000000,
                },
                ...[
                  { _id: 5, label: "5 Items", value: 5 },
                  { _id: 10, label: "10 Items", value: 10 },
                  { _id: 20, label: "20 Items", value: 20 },
                  { _id: 30, label: "30 Items", value: 30 },
                  { _id: 50, label: "50 Items", value: 50 },
                  { _id: 100, label: "100 Items", value: 100 },
                ],
              ]}
              getSelectedValue={(e) => {
                props.setLimit(e.value);
                setOffset(0);
              }}
            />
          ) : null}
        </div>

        <div className="ml-4">
          {window.location.pathname === "/appoinments" &&
            companyList.length > 1 && (
              <Select
                xPlacement={"left"}
                dropdownButtonClass={"!bg-white"}
                selectedValue={selctedCompanyId}
                dropdownData={companyList}
                label={"Select Companies"}
                getSelectedValue={(e) => handleSelect(e.value, "company")}
                resetValue={resetValue}
              />
            )}
        </div>

        <div className="ml-4">
          {window.location.pathname === "/invoices" &&
            companyList.length > 1 && (
              <Select
                xPlacement={"left"}
                dropdownButtonClass={"!bg-white"}
                selectedValue={selctedCompanyId}
                dropdownData={companyList}
                label={"Select Companies"}
                getSelectedValue={(e) => props.setCompany_id(e.value)}
                resetValue={resetValue}
              />
            )}
        </div>
        <div className="ml-4">
          {window.location.pathname === "/drivers" &&
            companyList.length > 1 && (
              <Select
                xPlacement={"left"}
                dropdownButtonClass={"!bg-white"}
                selectedValue={selctedCompanyId}
                dropdownData={companyList}
                label={"Select Companies"}
                getSelectedValue={(e) => props.setCompany_id(e.value)}
                resetValue={resetValue}
              />
            )}
        </div>
        <div className="ml-5">
          {window.location.pathname === "/invoices" &&
            companyList.length > 1 && (
              <>
               <div className="text-sm font-medium text-slate-500 mb-1 ml-auto">Select Date</div>
                <Flatpickr
                  className="relative w-full h-10 flex items-center justify-between focus:ring-0 transition-all duration-200 gap-2 pr-3 pl-3 rounded border border-slate-200 !bg-white"
                  ref={flatPickr}
                  placeholder={"Select date-range"}
                  options={{
                    altInput: true,
                    altFormat: 'F j, Y',
                    mode: 'range',
                    defaultDate: [moment(new Date()).startOf("month").toISOString(), moment(new Date()).endOf("month").toISOString()],
                    dateFormat: 'd.m.y',
                  }}
                  onChange={(e) => {
                    // onChange(e)
                    if ((e[0] !== undefined) && (e[1] !== undefined)) {
                      props.setDateRange({
                        start: moment(new Date(e[0])).format().slice(0, 10),
                        end: moment(new Date(e[1])).format().slice(0, 10),
                      })

                    }

                    console.log(e[1], "onchange", e[0])
                    // setDateRange(e)
                  }}
                />
              </>

            )}
        </div>


        <div className="ml-4">
          {window.location.pathname === "/appoinments" &&
            driverList.length > 1 && (
              <Select
                xPlacement={"left"}
                dropdownButtonClass={"!bg-white"}
                selectedValue={selectedDriverId}
                dropdownData={driverList}
                label={"Select Drivers"}
                getSelectedValue={(e) => handleSelect(e.value, "driver")}
                resetValue={resetValue}
              />
            )}
        </div>

        <div className="ml-4">
          {window.location.pathname === "/appoinments" &&
            carTypeList.length > 1 && (
              <Select
                xPlacement={"left"}
                dropdownButtonClass={"!bg-white"}
                // selectedValue={"0"}
                selectedValue={selectedTypeId}
                dropdownData={carTypeList}
                label={"Select Car Type"}
                getSelectedValue={(e) => handleSelect(e.value, "cartype")}
                resetValue={resetValue}
              />
            )}
        </div>

        <div className="ml-4">
          {window.location.pathname === "/appoinments" &&
            carServiceList.length > 1 && (
              <Select
                xPlacement={"left"}
                dropdownButtonClass={"!bg-white"}
                selectedValue={selectedServiceId}
                dropdownData={carServiceList}
                label={"Select Car Service"}
                getSelectedValue={(e) => handleSelect(e.value, "service")}
                resetValue={resetValue}
              />
            )}
        </div>

        <div className="relative ml-auto flex justify-end items-center gap-3 mt-5">
          <div className="ml-4">
            {window.location.pathname === "/appoinments" &&
              carServiceList.length > 1 && (
                <Button
                  buttonType={"button"}
                  buttonIcon={"fa-light  fa-rotate-right"}
                  buttonIconPosition={"left"}
                  buttonLabel={props.addName}
                  buttonHasLink={props.addLink ? true : false}
                  buttonLink={props.addLink || ""}
                  functions={() => handelReset()}
                />
              )}
          </div>
          {window.location.pathname === "/appoinments" && (
            // <button
            //   type="button"
            //   className="flex justify-center items-center gap-2 bg-sky-500 text-white rounded text-base h-10 py-0 px-3 transition-all duration-200 cursor-pointer undefined ml-2 border-sky-500"
            // >
            //   <button onClick={() => createPDF()}>PDF Export</button>
            // </button>
            <div>
              <button type="button"
                className="flex justify-center items-center gap-2 text-sm bg-sky-500 text-white rounded h-10 py-0 px-3 transition-all duration-200 cursor-pointer undefined ml-2 border-sky-500"
                onClick={openLinkInNewTab}
              >
                <PDFDownloadLink
                  fileName="somename.pdf"
                  document={<MyDocument />}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? "Loading document..." : openPDF(url)
                  }
                  Download
                </PDFDownloadLink>
              </button>
            </div>
          )}

          <div className="relative">
            {showSearchButton ? (
              <Search
                placeholder={"Search"}
                value={props.keyword}
                setInput={props.setKeyword}
              />
            ) : null}
          </div>
          {props.extraSelects || ""}
        </div>
      </div>
      <div className="w-full bg-white rounded-md shadow overflow-hidden">
        {children}
      </div>
      {props.noOfPage > 1 && (
        <div className="flex justify-end my-4">
          <ReactPaginate
            breakLabel="..."
            breakClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            nextLabel={<i class="fa-regular fa-fw fa-chevron-right"></i>}
            nextClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            onPageChange={props.onPageChange || (() => { })}
            pageRangeDisplayed={3}
            pageCount={props.noOfPage}
            containerClassName="flex justify-center items-center text-slate-80 text-sm font-medium mt-8 lg:mt-0 space-x-2"
            activeClassName="hover:bg-sky-500 bg-sky-500 text-white"
            pageLinkClassName="flex justify-center items-center w-full h-full"
            previousLabel={<i class="fa-regular fa-fw fa-chevron-left"></i>}
            pageClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            previousClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </div>
  );
};

export default ListLayout;
