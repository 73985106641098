import { useEffect } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';

import FreeAuthRoute from './routes/FreeAuthRoute';
import ProtectedRoute from './routes/ProtectedRoute';

import AuthLayout from './layouts/AuthLayout';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ForgotPassword from './pages/ForgotPassword';
import DefaultLayout from './layouts/DefaultLayout';
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings';
import AdminProfile from './pages/AdminProfile';

//CMS
import EmailTemplates from './pages/EmailTemplates';
import ManageEmailTemplate from './pages/ManageEmailTemplate';

//CRM
import UserManage from './pages/UserManage';
import Users from './pages/Users';
import PasswordCreate from './pages/PasswordCreate';
import Notification from './pages/Notification';

import CarServices from './pages/CarServices';

import Appoinments from './pages/Appoinments';

import CarTypes from './pages/CarTypes';
import AssignPrice from './pages/AssignPrice';
import FullCalender from './components/modals/FullCalender';
import TimeSlot from './pages/TimeSlot';
import Plans from './pages/Plans';
import Pages from './pages/Pages';
import InvoiceList from './pages/InvoiceList';
import { useDispatch } from 'react-redux';
import { postData } from './services/api';
import { siteSettingsImage, favIconImage } from './store/settingsSlice';
import Head from './components/shared/Head';

const CreateRoutes = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    async function siteSettingData() {
      try {
        const res = await postData('site-settings/details', {});

        if (res.status === 200) {
          const data = res.data;
          dispatch(siteSettingsImage(data.logo))
          dispatch(favIconImage(res.data.favicon))
        }
      } catch (error) {
        console.log('Error site setting details catch', error.message);
      }
    }

    siteSettingData();
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Head />
      <ScrollToTop>
        <Routes>
          <Route path='/' element={<AuthLayout />}>
            <Route
              path='/'
              exact
              element={
                <FreeAuthRoute>
                  <Login />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path='/signin' element={<AuthLayout />}>
            <Route
              path='/signin'
              exact
              element={
                <FreeAuthRoute>
                  <Login />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path='/signup' element={<AuthLayout />}>
            <Route
              path='/signup'
              exact
              element={
                <FreeAuthRoute>
                  <Signup />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path='/forgot-password' element={<AuthLayout />}>
            <Route
              path='/forgot-password'
              exact
              element={
                <FreeAuthRoute>
                  <ForgotPassword />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path='/manage' element={<AuthLayout />}>
            <Route
              path='/manage'
              exact
              element={
                <FreeAuthRoute>
                  <PasswordCreate />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path='/dashboard' element={<DefaultLayout />}>
            <Route
              path='/dashboard'
              exact
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/settings' element={<DefaultLayout />}>
            <Route
              path='/settings'
              exact
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* CMS Part  */}
          <Route path='/email-templates' element={<DefaultLayout />}>
            <Route
              path='/email-templates'
              exact
              element={
                <ProtectedRoute>
                  <EmailTemplates />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/edit-email/:id' element={<DefaultLayout />}>
            <Route
              path='/edit-email/:id'
              exact
              element={
                <ProtectedRoute>
                  <ManageEmailTemplate />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path='/site-settings' element={<DefaultLayout />}>
            <Route
              path='/site-settings'
              exact
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* CRM Part  */}
          <Route path='/user/add' element={<DefaultLayout />}>
            <Route
              path='/user/add'
              exact
              element={
                <ProtectedRoute>
                  <UserManage />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/dashboard' element={<DefaultLayout />}>
            <Route
              path='/dashboard'
              exact
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/users' element={<DefaultLayout />}>
            <Route
              path='/users'
              exact
              element={
                <ProtectedRoute>
                  <Users />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path='/admin' element={<DefaultLayout />}>
            <Route
              path='/admin'
              exact
              element={
                <ProtectedRoute>
                  <Users />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path='/drivers' element={<DefaultLayout />}>
            <Route
              path='/drivers'
              exact
              element={
                <ProtectedRoute>
                  <Users />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path='/edit/user/:id' element={<DefaultLayout />}>
            <Route
              path='/edit/user/:id'
              exact
              element={
                <ProtectedRoute>
                  <UserManage />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/profile' element={<DefaultLayout />}>
            <Route
              path='/profile'
              exact
              element={
                <ProtectedRoute>
                  <AdminProfile />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/notification' element={<DefaultLayout />}>
            <Route path='/notification' exact element={<Notification />} />
          </Route>
          <Route path='/car_types' element={<DefaultLayout />}>
            <Route
              path='/car_types'
              exact
              element={
                <ProtectedRoute>
                  <CarTypes />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/car-services' element={<DefaultLayout />}>
            <Route
              path='/car-services'
              exact
              element={
                <ProtectedRoute>
                  <CarServices />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/calender-view' element={<DefaultLayout />}>
            <Route
              path='/calender-view'
              exact
              element={
                <ProtectedRoute>
                  <FullCalender />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/invoices' element={<DefaultLayout />}>
            <Route
              path='/invoices'
              exact
              element={
                <ProtectedRoute>
                  <InvoiceList />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/appoinments' element={<DefaultLayout />}>
            <Route
              path='/appoinments'
              exact
              element={
                <ProtectedRoute>
                  <Appoinments />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/services_plan' element={<DefaultLayout />}>
            <Route
              path='/services_plan'
              exact
              element={
                <ProtectedRoute>
                  <AssignPrice />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/time_slots' element={<DefaultLayout />}>
            <Route
              path='/time_slots'
              exact
              element={
                <ProtectedRoute>
                  <TimeSlot />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path='/site-settings' element={<DefaultLayout />}>
            <Route
              path='/site-settings'
              exact
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/plans' element={<DefaultLayout />}>
            <Route
              path='/plans'
              exact
              element={
                <ProtectedRoute>
                  <Plans />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path='/pages' element={<DefaultLayout />}>
            <Route
              path='/pages'
              exact
              element={
                <ProtectedRoute>
                  <Pages />
                </ProtectedRoute>
              }
            />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default CreateRoutes;
