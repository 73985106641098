import { useRef } from 'react';

const ButtonFile = ({
  buttonClasses,
  buttonIcon,
  buttonIconPosition,
  buttonLabel,
  buttonLabelClasses,
  buttonFunction,
  functions,
  accepts,
  value = null,
  ...props
}) => {
  const imgRef = useRef(null)

  return (
    <>
      <div
        className={
          'relative flex justify-center items-center gap-2 bg-sky-500 text-white rounded text-lg h-10 py-0 px-3 transition-all duration-200 overflow-hidden cursor-pointer ' +
          buttonClasses
        }
      >
        <input
          ref={imgRef}
          type={'file'}
          className='absolute top-0 right-0 z-10 opacity-0 cursor-pointer w-[1000px] h-full'
          onChange={(e) => {
            functions(e)
            imgRef.current.value = null
          }}
          accept={accepts}
          value={value}
        />
        {buttonIconPosition === 'left' && (
          <i className={'fa-fw ' + buttonIcon}></i>
        )}
        {buttonLabel && (
          <span className={'text-sm font-medium ' + buttonLabelClasses}>
            {buttonLabel}
          </span>
        )}
        {buttonIconPosition === 'right' && (
          <i className={'fa-fw ' + buttonIcon}></i>
        )}
      </div>
    </>
  );
};

export default ButtonFile;
