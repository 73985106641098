import { useSelector } from "react-redux"
import { Helmet } from "react-helmet"

const Head = () => {
  const siteSettingsData = useSelector(state => state.siteLogo)

  return (
    <Helmet>
      <link rel="icon" href={siteSettingsData.favIcon}></link>
    </Helmet>
  )
}

export default Head