import React,{useState,useEffect} from 'react'
import TableAlt from '../components/elements/table/TableAlt'
import ListLayout from '../containers/ListLayout'
import { setTitle } from '../helpers/MetaTag';

import { useSelector } from 'react-redux';

import { postData } from '../services/api';

import toastr from 'toastr';


import AddEditSlot from '../components/modals/AddEditSlot';


export default function TimeSlot() {
  setTitle("Ryen bilpleie | Time Slots")
    const user = useSelector((state) => state.auth.user);
    const [limit,setLimit]=useState(10)
    const [keyword,setKeyword]=useState('')
    const[offset,setOffset]=useState('')
    const [list, setList] = useState({
        loading: false,
        data: [],
        count: 0,
      })
      const [statusFilterData, setStatusFilterData] = useState([
        { _id: 1, name: 'Active', type: 'checkbox', value: true, isChecked: false },
        { _id: 2, name: 'Inactive', type: 'checkbox', value:false, isChecked: false}
      ])
      const [sortField, setSortField] = useState(null);
      const [sortType, setSortType] = useState(null);
    
      const [showModal, setShowModal] = useState(false)
      const [editData, setEditData] = useState({})
  
      const actionData=(item)=>[
        {
            name: 'Edit',
            icon: 'fa-pen-to-square',
            type: 'button',
            onClick: id => {
              setShowModal(true)
              setEditData(list.data.find(item => item._id === id) || {})
            }
          },
          {
            name: item.status ? 'Inactive' : 'Active',
            icon: item.status ? 'fa-circle-exclamation' : 'fa-circle-check',
            type: 'button',
            onClick: changeStatus,
          },
          {
            name: 'Delete',
            icon: 'fa-trash-can',
            type: 'button',
            actionType: 'delete',
            deleteLink: '/crud/delete',
            deletePayload: { id: [item._id], table_name: 'timeslot' },
          },


      ]


      
 
    const tableHeadData=[

        {
       
            width: 15,
            title: 'Start time',
            name: 'start_time',
            align: 'left',
            sortable: true,
            filterable: false,
          },
        {
       
            width: 15,
            title: 'End time',
            name: 'end_time',
            align: 'left',
            sortable: true,
            filterable: false,
          },
          {
       
            width: 15,
            title: 'Status',
            name: 'status',
            align: 'left',
            sortable: true,
            filterable: true,
            filterOptions: statusFilterData,
            onFilterChange: (id) => {

              setStatusFilterData(data => data.map(item => item._id !== id ? item : {
                ...item,
                isChecked: !item.isChecked,
                touched:true,
              }))
            }
          },
          {
            action:true
          }

    ]
    const changeStatus = async (id) => {
        const res = await postData('/crud/statusChange', {
          table_name: 'timeslot',
          id: [id],
          column_name: 'status',
          column_value: !(
            list.data.find((item) => item._id === id).status || false
          ),
        });
    
        if (res.status === 200) {
          setList((list) => ({
            ...list,
            data: list.data.map((item) =>
              item._id !== id
                ? item
                : {
                    ...item,
                    status: !(
                      list.data.find((item) => item._id === id).status ||
                      false
                    ),
                    touched:true
                  }
            ),
          }));
          toastr.success(res.message)
        } else {
          toastr.error(res.message);
        }
      };
    useEffect(() => {
        // if (user?._id) {
          const loadData = async () => {
            try {
                setList((list) => ({ ...list, loading: true }));
            const searchParams = {}
            if (keyword) {
              searchParams.start_time = { $regex: keyword, $options: 'i' }
            }
            

            if(statusFilterData.filter((item)=>item.touched&&item.isChecked).length) {
                searchParams.status={$in:statusFilterData.filter((item)=>item.touched &&item.isChecked).map((item)=>item.value)}
               
            }
            

    
            const res = await postData('/crud/list', {
              table_name: 'timeslot',
            
              queryParams: {
                deleted_at: null,
                ...searchParams
              },
              selectParams: {
                start_time: true,
                end_time:true,
                status: true,

                ordering: true,
                created_at: true,
              },
              sortQuery: sortField && sortType ? { [sortField] : sortType } : { ordering: 1 },
              limit,
              offset,
             
            });
            if (res.status === 200) {
              setList((list) => ({
                ...list,
                data: res.data.list,
                loading: false,
                count: res.data.count,
              }));
            } else {
              setList((list) => ({ ...list, loading: false }));
            }
            } catch (error) {
                console.log(error);
            }
            
          };
    
          loadData();
        // }
      }, [user?._id, keyword, limit, offset, sortField, sortType, statusFilterData.filter((item)=>item.touched&&item.isChecked).length]);
      const onDrag = async (data = []) => {
        const res = await postData('/crud/ordering', {
          table_name: 'timeslot',
          ordering_data: data.map((item, i) => ({ id: item._id, ordering: i }))
        })
    
        if (res.status !== 200) {
          toastr.error(res.message)
        }  
        else{
          toastr.success(res.message)
        }
      }

      


  return (
    <>
    <ListLayout
    title='Time Slots'
    addName='Add Time Slot '
    onAdd={() => setShowModal(true)}
    showSearchButton={false}
    limit={limit}
    setLimit={setLimit}
    setKeyword={setKeyword}
    keyword={keyword}
    noOfPage={Math.ceil(list.count / limit)}
    onPageChange={(val) => setOffset(val.selected * limit)}
  >
    <TableAlt
      tableHeadData={tableHeadData}
      tableData={list.data}
      loading={list.loading}
      sortField={sortField}
      sortType={sortType}
      setSortField={setSortField}
      setSortType={setSortType}
      actionData={actionData}
      setList={setList}
      isDraggable={true}
      onDrag={onDrag}
    />
  </ListLayout>

  <AddEditSlot

    show={showModal}
    setShow={setShowModal}
    title={Object.keys(editData).length > 0 ? 'Edit Slot' : 'Add Slot'}
    list={list.data}
    setList={setList}
    editData={editData}
    setEditData={setEditData}
  />

</>
  )

}
