import { useState, useEffect, useRef } from 'react';
import AddEditLayoutModal from '../../containers/modals/AddEditLayoutModal';
import Input from '../form/Input';
import { Editor } from '@tinymce/tinymce-react';
import { postData } from '../../services/api';
import toastr from 'toastr';
// import Textarea from "../form/Textarea";
import { tinymceApiKey } from '../../helpers';
import Image from '../elements/Image';
import ButtonFile from '../form/ButtonFile';
import Button from '../form/Button';

export default function AddEditPlans({
  title = '',
  show = false,
  editData = {},
  setEditData = () => {},
  setShow = () => {},
  list = [],
  setList = () => {},
  addType = 'ASC',
}) {
  const [disabled, setDisabled] = useState();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [imgId, setImgId] = useState(null);
  const editorRef = useRef(null);
  const [image, setImage] = useState(null);
  const [alias, setAlias] = useState('');
  const [bannerDescription, setBannerDescription] = useState('');
  const [bannerTitle, setBannerTitle] = useState('');
  const [bannerButton, setBannerButton] = useState('');

  useEffect(() => {
    if (editData?._id) {
      setName(editData.title);
      setDescription(editData.description);
      setImage(editData.image);
      setImgId(editData.image);
      setAlias(editData.alias);
      setBannerButton(editData.banner_button);
      setBannerDescription(editData.banner_description);
      setBannerTitle(editData.banner_title);
      console.log(editData, 'editdata');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData?._id]);

  const closeModal = () => {
    setShow(false);
    setName('');
    setDescription('');

    setDisabled(false);
    setEditData({});
  };

  const onSubmit = async () => {
    setDisabled(true);

    const res = await postData(`crud/${editData?._id ? 'edit' : 'add'}`, {
      table_name: 'pages',
      data: {
        title: name,
        description: description,
        banner_image_id: imgId,
        banner_description: bannerDescription,
        banner_title: bannerTitle,
        banner_button: bannerButton,

        id: editData?._id || null,
        ordering: editData?._id ? editData.ordering : list.length,
      },
      validationRules: {
        'data.title': 'required',
        // 'data.ordering': 'required'
      },
      validationMessages: {
        'required.data.title': 'Title Field is required',
        // 'required.data.ordering': 'Ordering Field is required',
      },
    });

    if (res.status === 200) {
      if (!editData?._id) {
        if (addType !== 'DESC') {
          setList((list) => ({
            ...list,
            data: [...list.data, res.data],
            count: list.count + 1,
          }));
        } else {
          setList((list) => ({
            ...list,
            data: [res.data, ...list.data],
            count: list.count + 1,
          }));
        }
      } else if (editData && editData?._id) {
        setList((list) => ({
          ...list,
          data: list.data.map((item) =>
            item._id !== editData._id ? item : res.data
          ),
        }));
      }

      closeModal();
      toastr.success(res.message);
    } else {
      setDisabled(false);
      toastr.error(res.message);
    }
  };
  const onImageChange = async (e) => {
    setDisabled(true);
    if (e.target.files.length) {
      setImage(URL.createObjectURL(e.target.files[0]));
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      const imgRes = await postData(
        'file/upload',
        formData,
        'multipart/form-data'
      );

      if (imgRes.status === 200) {
        setImgId(imgRes?.data._id);
        setDisabled(false);
      } else {
        setDisabled(false);
      }
    }
  };
  console.log(list, 'list');
  console.log(editData, 'editdata');
  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      modalSize='xxxxl'
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
      onSubmit={onSubmit}
    >
      <div className='relative w-full'>
        <div className='relative w-full flex gap-4'>
          <div className='w-2/3'>
            <div className='mb-4 '>
              <Input
                label={'title '}
                labelClasses={'!text-xs capitalize'}
                inputType={'text'}
                inputPlaceholder={'Enter Title'}
                inputValue={name}
                setInput={setName}
              />
            </div>
            <div className='mb-4'>
              <Input
                label={'Alias '}
                labelClasses={'!text-xs capitalize'}
                inputType={'text'}
                inputPlaceholder={' Set Alias '}
                inputValue={alias}
                setInput={setAlias}
                isDisabled={true}
              />
            </div>
            <div className='relative'>
              <div className='text-sm font-medium text-slate-500 mb-1'>
                Description
              </div>
              <Editor
                apiKey={tinymceApiKey()}
                onInit={(evt, editor) => (editorRef.current = editor)}
                value={description}
                init={{
                  height: 300,
                  menubar: false,
                  plugins: [
                    'code',
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                  ],
                  toolbar:
                    'code | undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help ',
                  content_style:
                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                }}
                onEditorChange={(newText) => setDescription(newText)}
              />
            </div>
            {/* bannersection */}

            {editData && editData.hasOwnProperty('banner_title') ? (
              <>
                {' '}
                <div className='mb-4 '>
                  <Input
                    label={'Banner Title'}
                    labelClasses={'!text-xs capitalize'}
                    inputType={'text'}
                    inputPlaceholder={'Enter Banner Title'}
                    inputValue={bannerTitle}
                    setInput={setBannerTitle}
                  />
                </div>
                <div className='mb-4'>
                  <Input
                    label={'Banner Button'}
                    labelClasses={'!text-xs capitalize'}
                    inputType={'text'}
                    inputPlaceholder={' Enter Banner Button '}
                    inputValue={bannerButton}
                    setInput={setBannerButton}
                    isDisabled={false}
                  />
                </div>
                <div className='relative'>
                  <div className='text-sm font-medium text-slate-500 mb-1'>
                    {' '}
                    Banner Description
                  </div>
                  <Editor
                    apiKey={tinymceApiKey()}
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    value={bannerDescription}
                    init={{
                      height: 300,
                      menubar: false,
                      plugins: [
                        'code',
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                      ],
                      toolbar:
                        'code | undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help ',
                      content_style:
                        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    }}
                    onEditorChange={(newText) => setBannerDescription(newText)}
                  />
                </div>
              </>
            ) : null}
          </div>
          <div className='w-1/3'>
            <div className='relative w-full mt-5'>
              <div className='relative'>
                {image !== null && (
                  <>
                    <Button
                      buttonClasses={
                        '!absolute !z-10 !-top-5 !right-0 !cursor-pointer !bg-white !text-slate-400 !w-6 !h-6 !rounded-full !shadow !text-sm mt-3'
                      }
                      buttonType={'button'}
                      buttonIcon={'fa-light fa-xmark'}
                      buttonIconPosition={'left'}
                      buttonHasLink={false}
                      functions={() => setImage(null)}
                    />
                    <div className='w-full h-40 border rounded-lg overflow-hidden'>
                      <Image
                        src={image}
                        alt={name}
                        width={'100%'}
                        height={'100%'}
                        effect={'blur'}
                        classes={'object-cover'}
                      />
                    </div>
                  </>
                )}
                {
                  editData.alias === 'service' &&
                  <ButtonFile
                    functions={onImageChange}
                    buttonLabel='Upload Banner Image'
                    buttonIcon={'far fa-cloud-upload-alt'}
                    buttonIconPosition='left'
                    buttonClasses={
                      '!bg-white !text-slate-400 border border-slate-200 mt-3'
                    }
                  />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </AddEditLayoutModal>
  );
}
