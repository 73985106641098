import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { setTitle } from "../helpers/MetaTag";



import ListLayout from "../containers/ListLayout";
import { postData } from "../services/api";

import toastr from "toastr";

import { API_URL } from "../config/host";
import ManageCarTypes from "../components/modals/ManageCategory";

import 'flatpickr/dist/flatpickr.css';
import Header from "../components/assignPriceItem/Header";
import moment from "moment/moment";

export default function AssignPrice() {
  setTitle("Ryen bilpleie  | Services Plan")
  const user = useSelector((state) => state.auth.user);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [dateRange, setDateRange] = useState({});
  const [price, setPrice] = useState({});
  const [list, setList] = useState({
    loading: false,
    data: [],
    count: 0,
  });
  const [service, setService] = useState({
    loading: false,
    data: [],
    count: 0,
  });
  const [statusFilterData, setStatusFilterData] = useState([
    { _id: 1, name: "Active", type: "checkbox", value: true, isChecked: false },
    {
      _id: 2,
      name: "Inactive",
      type: "checkbox",
      value: false,
      isChecked: false,
    },
  ]);
  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState({});

  const handelVisible = (id) => {
    setList((list) => ({
      ...list,
      data: list.data.map((item) =>
        item._id !== id
          ? item
          : {
            ...item,
            visible: item.visible ? false : true,
          }
      ),
    }));
  };



  useEffect(() => {
    // if (user?._id) {
    const loadData = async () => {
      setList((list) => ({ ...list, loading: true }));
      const searchParams = {};
      if (keyword) {
        searchParams.title = { $regex: keyword, $options: "i" };
      }

      if (
        statusFilterData.filter((item) => item.touched && item.isChecked).length
      ) {
        searchParams.status = {
          $in: statusFilterData
            .filter((item) => item.touched && item.isChecked)
            .map((item) => item.value),
        };
      }
      if (dateRange.start && dateRange.end) {
        searchParams.created_at = {
          $gte: new Date(dateRange.start),
          $lte: new Date(dateRange.end),
        };
      }

      const res = await postData("/crud/list", {
        table_name: "carType",
        dateRange,
        queryParams: {
          deleted_at: null,
          ...searchParams,
        },
        selectParams: {
          title: true,
          status: true,
          image: {
            $cond: ["$image", { $concat: [API_URL, "$image"] }, null],
          },
          ordering: true,
          created_at: true,
        },
        sortQuery:
          sortField && sortType ? { [sortField]: sortType } : { ordering: 1 },
        limit,
        offset,
      });

      if (res.status === 200) {
        setList((list) => ({
          ...list,
          data: res.data.list,
          loading: false,
          count: res.data.count,
        }));
      } else {
        setList((list) => ({ ...list, loading: false }));
      }

      const result3 = await postData("crud/list", {
        table_name: "assignprice",
        dateRange,
        queryParams: {
          deleted_at: null,
          ...searchParams,
        },

        queryParams: {
          deleted_at: null,
          ...searchParams,
        },
        selectParams: {
          car_type_id: true,
          service_id: true,
          price: true,
          status: true,

          created_at: true,
        },
        sortQuery:
          sortField && sortType ? { [sortField]: sortType } : { ordering: 1 },
        limit: 1000000,
        offset,
      });
      if (result3.status === 200) {
        let obj = {};
        result3.data.list.forEach((element) => {
          obj[`${element.car_type_id}-${element.service_id}`] = {
            price: element.price,
            id: element._id,
          };
        });
        console.log(obj)
        setPrice(obj);

      } else {
        setPrice({});
      }
    };

    loadData();
    // }
  }, [
    user?._id,
    keyword,
    limit,
    offset,
    sortField,
    sortType,
    dateRange,
    statusFilterData.filter((item) => item.touched && item.isChecked).length,
  ]);



  useEffect(() => {
    const loadData2 = async () => {
      setService((service) => ({ ...service, loading: true }));
      const searchParams = {};
      if (keyword) {
        searchParams.title = { $regex: keyword, $options: "i" };
      }

      if (
        statusFilterData.filter((item) => item.touched && item.isChecked).length
      ) {
        searchParams.status = {
          $in: statusFilterData
            .filter((item) => item.touched && item.isChecked)
            .map((item) => item.value),
        };
      }

      const res = await postData("/crud/list", {
        table_name: "services",
        dateRange,
        queryParams: {
          deleted_at: null,
          ...searchParams,
        },
        selectParams: {
          name: true,
          status: true,
          image: {
            $cond: ["$image", { $concat: [API_URL, "$image"] }, null],
          },
          ordering: true,
          created_at: true,
        },
        sortQuery:
          sortField && sortType ? { [sortField]: sortType } : { ordering: 1 },
        limit,
        offset,
      });
      if (res.status === 200) {
        setService((service) => ({
          ...service,
          data: res.data.list,
          loading: false,
          count: res.data.count,
        }));
      } else {
        setService((service) => ({ ...service, loading: false }));
      }
    };

    loadData2();


  }, [
    statusFilterData.filter((item) => item.touched && item.isChecked).length,
  ]);




  const assingPrice = async (id1, id2) => {

    let isAddEdit = price[`${id1}-${id2}`] ? true : false;

    let a = document.getElementById(`${id1}-${id2}`);

    const result = await postData(`/crud/${isAddEdit ? "edit" : "add"}`, {
      table_name: "assignprice",
      data: {
        car_type_id: id1,
        service_id: id2,
        price: a.value,

        id: isAddEdit ? price[`${id1}-${id2}`].id : null,
      },
      validationRules: {
        "data.price": "required",

      },
      validationMessages: {
        "required.data.price": "price Field is required",

      },
    });
    if (result.status === 200) {
      toastr.success(result.message);
    } else {
      toastr.error(result.message);
    }
  };

  return (
    <>
      <ListLayout
        title="Services Plan"

        onAdd={() => setShowModal(true)}
        showAddButton={true}
        showSearchButton={true}
        addName="Add Car Type"

        limit={limit}
        setLimit={setLimit}
        setKeyword={setKeyword}
        keyword={keyword}
        noOfPage={Math.ceil(list.count / limit)}
        onPageChange={(val) => setOffset(val.selected * limit)}
        setOffset={setOffset}
      >
        <div className="relative w-full">
          <Header/>



          <div>
            {list.data.map((item) => {
              return (
                <div
                  className="text-slate-600 text-xs xl:text-sm font-semibold border-b border-neutral-20/30"
                  key={item._id}
                >
                  <div className="flex w-full bg-white p-3">
                    <div className="py-2 w-1/4 min-w-[120px] flex items-center">
                      <div className="flex flex-col text-sm font-normal text-slate-600 ">
                        {item.title}
                      </div>
                    </div>
                    <div className="py-2 w-1/4 min-w-[120px] flex items-center">
                      <div className="flex flex-col text-sm font-normal text-slate-600 ">
                        {item.status === true ? "Active" : "Inactive"}
                      </div>
                    </div>
                    <div className="py-2 w-1/4 min-w-[120px] flex items-center">
                      <div className="flex flex-col text-sm font-normal text-slate-600 ">
                        {item.created_at
                          ? moment(new Date(item.created_at)).format("Do MMM YY")
                          : ""}
                      </div>
                    </div>
                    <div className="py-2 w-1/4 min-w-[120px] flex items-center justify-end">
                      <div className="flex flex-col text-sm font-normal text-slate-600">
                        <span className="cursor-pointer">
                          <i
                            className={
                              item.visible
                                ? "fa-fw fa-light fa-minus text-red-400"
                                : "fa-fw fa-light fa-plus text-green-500"
                            }
                            onClick={() => handelVisible(item._id)}
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>

                  {item.visible && (
                    <div className="flex">
                      <div className="p-0 flex w-full">
                        <div className="p-3 bg-slate-20/50 flex w-full min-w-[720px]">
                          <div className="w-full flex bg-slate-100/50 rounded-md shadow-2ds divide-y p-3">
                            <div className="w-full text-xs xl:text-sm">
                              <div className="flex w-full">
                                <div className="pt-3 pb-2 w-2/6 min-w-[240px] border-neutral-20/30 flex items-center">
                                  <div className="flex flex-col">
                                    <div className="text-slate-600 text-lg font-semibold">
                                      Services
                                    </div>
                                    {service &&
                                      price &&
                                      service.data.map((item1) => {

                                        return (
                                          <div
                                            className="flex flex-col"
                                            key={item1._id}
                                          >
                                            <div className="text-slate-400 text-sm font-medium mt-2">
                                              {item1.name}
                                            </div>

                                            <div className="flex items-center">
                                              <input
                                                type="number"
                                                value={
                                                  price !== {} &&
                                                    price.hasOwnProperty(
                                                      `${item._id}-${item1._id}`
                                                    ) &&
                                                    price[
                                                      `${item._id}-${item1._id}`
                                                    ].price
                                                    ? price[
                                                      `${item._id}-${item1._id}`
                                                    ].price
                                                    : null
                                                }
                                                onChange={(e) => {
                                                  if (
                                                    price.hasOwnProperty(
                                                      `${item._id}-${item1._id}`
                                                    )
                                                  ) {
                                                    setPrice({
                                                      ...price,
                                                      [`${item._id}-${item1._id}`]:
                                                      {
                                                        ...price[
                                                        `${item._id}-${item1._id}`
                                                        ],
                                                        price: e.target.value,
                                                      },
                                                    });
                                                  } else {
                                                    document.getElementById(
                                                      `${item._id}-${item1._id}`
                                                    ).value = e.target.value;
                                                  }
                                                }}
                                                className="w-full min-w-[500px] bg-slate-100  border-neutral-300 focus:ring-0 focus:border-neutral-300 rounded"
                                                id={`${item._id}-${item1._id}`}
                                              />
                                              <button
                                                type="button"
                                                className="flex justify-center items-center gap-2 bg-sky-500 text-white rounded text-base h-10 py-0 px-3 transition-all duration-200 cursor-pointer undefined ml-2 border-sky-500"
                                                onClick={() => {
                                                  assingPrice(
                                                    item._id,
                                                    item1._id
                                                  );
                                                }}
                                              >
                                                Save
                                              </button>
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>

        </div>
      </ListLayout>
      <ManageCarTypes
        show={showModal}
        setShow={setShowModal}
        title={
          Object.keys(editData).length > 0 ? "Edit Car Type" : "Add Car Type"
        }
        list={list.data}
        setList={setList}
        editData={editData}
        setEditData={setEditData}
      />
    </>
  );
}
