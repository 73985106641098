import { useState, useEffect } from "react";
import AddEditLayoutModal from "../../containers/modals/AddEditLayoutModal";
import Input from "../form/Input";
import Textarea from "../form/Textarea";

import { postData } from "../../services/api";

import toastr from "toastr";
import Image from "../elements/Image";
import ButtonFile from "../form/ButtonFile";
import Button from "../form/Button";
export default function AddEditCarServices({
  title = "",
  show = false,
  editData = {},
  setEditData = () => { },
  setShow = () => { },
  list = [],
  setList = () => { },
  addType = "ASC",
}) {
  const [disabled, setDisabled] = useState();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [short, setShort] = useState("");
  const [note, setNote] = useState("");
  const [image, setImage] = useState(null);
  useEffect(() => {
    if (editData?._id) {
      setName(editData.name);
      setDescription(editData.description);
      setShort(editData.short);
      setImage(editData.image);
    }
  }, [editData]);

  const closeModal = () => {
    setShow(false);
    setName("");
    setDescription("");
    setImage(null);
    setNote("");
    setShort("");
    setDisabled(false);
    setEditData({});
  };
  const onImageChange = (e) => {
    if (e.target.files.length) {
      setImage(e.target.files[0]);
    }
  };
  const onSubmit = async () => {
    setDisabled(true);

    let imageId = undefined;
    if (image instanceof File) {
      const formData = new FormData();
      formData.append("file", image);
      const imgRes = await postData(
        "file/upload",
        formData,
        "multipart/form-data"
      );
      if (imgRes.status === 200) {

        imageId = imgRes.data?._id;
      } else {
        toastr.error(imgRes.message);

      }
    }

    const res = await postData(`crud/${editData?._id ? "edit" : "add"}`, {
      table_name: "services",
      data: {
        name: name,
        short,
        description,
        note,

        image_id: imageId,
        id: editData?._id || null,
        ordering: editData?._id ? editData.ordering : list.length,
      },
      validationRules: {
        "data.name": "required",
        // 'data.ordering': 'required'
      },
      validationMessages: {
        "required.data.name": "Title Field is required",
        // 'required.data.ordering': 'Ordering Field is required',
      },
    });

    if (res.status === 200) {
      if (!editData?._id) {
        if (addType !== "DESC") {
          setList((list) => ({
            ...list,
            data: [...list.data, res.data],
            count: list.count + 1,
          }));
        } else {
          setList((list) => ({
            ...list,
            data: [res.data, ...list.data],
            count: list.count + 1,
          }));
        }
      } else if (editData && editData?._id) {
        setList((list) => ({
          ...list,
          data: list.data.map((item) =>
            item._id !== editData._id ? item : res.data
          ),
        }));
      }

      closeModal();
      toastr.success(res.message);
      window.location.reload()
    } else {
      setDisabled(false);
      toastr.error(res.message);
    }
  };
  return (
    <AddEditLayoutModal
      title={title}
      show={show}
      modalSize="xxl"
      setShow={setShow}
      onClose={closeModal}
      disabled={disabled}
      onSubmit={onSubmit}
    >
      <div className="relative w-full">
        <div className="relative w-full flex gap-4">
          <div className="w-2/3">
            <div className="mb-4 ">
              <Input
                label={"Service Name"}
                labelClasses={"!text-xs capitalize"}
                inputType={"text"}
                inputPlaceholder={"Enter Service Name"}
                inputValue={name}
                setInput={setName}
              />
            </div>
            <div className="mb-4 ">
              <Input
                label={"Short Description"}
                labelClasses={"!text-xs capitalize"}
                inputType={"text"}
                inputPlaceholder={"Enter Short Description"}
                inputValue={short}
                setInput={setShort}
              />
            </div>

            <Textarea
              label={"Description"}
              labelClasses={"!text-xs"}
              inputType={"text"}
              inputPlaceholder={"Enter  Description"}
              inputValue={description}
              setTextarea={setDescription}
            />
          </div>
          <div className="w-1/3 ">
            {image !== null ? (
              <div className="relative w-full mt-5">
                <Button
                  buttonClasses={"!absolute !z-10 !-top-5 !right-0 !cursor-pointer !bg-white !text-slate-400 !w-6 !h-6 !rounded-full !shadow !text-sm mt-3"}
                  buttonType={"button"}
                  buttonIcon={"fa-light fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonHasLink={false}
                  functions={() => setImage(null)}
                />
                <div className="w-full h-40 border rounded-lg overflow-hidden">
                  <Image src={image} alt={name} />
                </div>
                <ButtonFile functions={onImageChange} buttonLabel="Upload Image" buttonIcon={"far fa-cloud-upload-alt"} buttonIconPosition="left" buttonClasses={"!bg-white !text-slate-400 border border-slate-200 mt-5"} />
              </div>
            ) : (

              <>
                <ButtonFile functions={onImageChange} buttonLabel="Upload Image" buttonIcon={"far fa-cloud-upload-alt"} buttonIconPosition="left" buttonClasses={"!bg-white !text-slate-400 border border-slate-200 mt-5"} />
              </>

            )}

          </div>
        </div>
      </div>

    </AddEditLayoutModal>
  );
}
