import { setTitle } from '../helpers/MetaTag';
import { Link } from 'react-router-dom';
import Image from '../components/elements/Image';

const Notification = () => {
  setTitle('Car Wash | Notifications');
  const dropdownData = [
    {
      _id:1, 
      user: "Sujan Dey", 
      image: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
      text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.", 
      date: "16 Nov 2022", 
      link: "/",
      isUnread: true
    },
    {
      _id:2, 
      user: "Sujay Mondal", 
      image: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
      text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.", 
      date: "16 Nov 2022", 
      link: "/",
      isUnread: true
    },
    {
      _id:3, 
      user: "Subhajit Saha", 
      image: "https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NTN8fHVzZXJ8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
      text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.", 
      date: "16 Nov 2022", 
      link: "/",
      isUnread: true
    },
    {
      _id:4, 
      user: "Chinmoy Ghosh", 
      image: "https://images.unsplash.com/photo-1530268729831-4b0b9e170218?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDF8fHVzZXJ8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
      text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.", 
      date: "16 Nov 2022", 
      link: "/",
      isUnread: false
    },
    {
      _id:5, 
      user: "Soumya Ghosh", 
      image: "https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzV8fHVzZXJ8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
      text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.", 
      date: "16 Nov 2022", 
      link: "/",
      isUnread: false
    },
    {
      _id:6, 
      user: "Madhumita Maity", 
      image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
      text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.", 
      date: "16 Nov 2022", 
      link: "/",
      isUnread: false
    },
    {
      _id:7, 
      user: "Animesh Manna", 
      image: "https://images.unsplash.com/photo-1544168190-79c17527004f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzJ8fHVzZXJ8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
      text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.", 
      date: "16 Nov 2022", 
      link: "/",
      isUnread: false
    },
    {
      _id:8, 
      user: "Sangita Manna", 
      image: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
      text: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.", 
      date: "16 Nov 2022", 
      link: "/",
      isUnread: false
    },
  ]

  return (
    <>
      <div className="relative flex justify-between items-center mb-4">
        <div className="text-3xl text-slate-800 font-bold">Notifications</div>
      </div>
      <div className="relative bg-white rounded shadow">
        <div className="py-4 divide-y divide-slate-100">
          {dropdownData.map((item) => (
            <Link to={item.link} className={"group flex py-4 px-6 gap-4 transition-all duration-200 hover:bg-slate-100 " + (item.isUnread?'bg-slate-50':'')} key={item._id}>
              <div className={"w-10 h-10 min-w-[40px] overflow-hidden rounded-full ring-1 ring-offset-2 " + (item.isUnread?'ring-sky-500':'ring-slate-200')}>
                <Image src={item.image} alt={item.user} width={'100%'} height={'100%'} effect={'blur'} classes={'object-cover'}/>
              </div>
              <div className="w-full relative flex flex-col gap-1">
                <div className="text-sm font-bold text-slate-700 leading-tight">{item.user}</div>
                <div className="text-xs text-slate-500 leading-tight">{item.text}</div>
                <div className={"text-[11px] leading-tight " + (item.isUnread?'text-slate-400':'text-slate-300')}>{item.date}</div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default Notification;