import React from 'react';
import SidebarMenuItem from '../navigation/SidebarMenuItem';

const SidebarMenuBlocks = ({menuTitle, menuData, isMenuOpen, ...props}) => {
  return (
    <>
      <div className={"relative py-4 space-y-2 transition-all duration-100 " + (isMenuOpen?"px-3":"px-4")}>
        {!isMenuOpen &&
        <div className={"text-xs uppercase text-slate-500 font-medium"}>{menuTitle}</div>
        }
        <div className={"space-y-1 -mx-1 " + (isMenuOpen?"flex flex-col items-center":"")}>
          {menuData.map((item) => (
            <SidebarMenuItem key={item._id} menuLink={item.menuLink} menuIcon={item.menuIcon} menuIconColor={item.menuIconColor} menuLabel={item.menuLabel} isMenuOpen={isMenuOpen}/>
          ))}
        </div>
      </div>
    </>
  );
};

export default SidebarMenuBlocks;