import React, { useState, useEffect, useRef } from "react";
import { setTitle } from "../helpers/MetaTag";
import Image from "../components/elements/Image";
import Button from "../components/form/Button";
import ButtonFile from "../components/form/ButtonFile";
import Input from "../components/form/Input";
import Textarea from "../components/form/Textarea";
import toastr from "toastr";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import { connect } from "react-redux";

import { postData } from "../services/api";
import { siteSettingsImage } from "../store/settingsSlice";


const Settings = ({ siteState, siteLogo }) => {
  setTitle("Ryen bilpleie| Settings");

  console.log(siteState, "state")


  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(null);
  const [title, setCompanyTitle] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  const [copyright, setCopyRight] = useState("");
  const [logo, setLogo] = useState("");
  const [favIcon, setFavicon] = useState("");

  const [logoId, setLogoId] = useState(null);
  const [favId, setFavId] = useState(null);
  const [SiteSettingsDetails, setSiteSettingsDetails] = useState({})
  const [siteIcon, setSiteIcon] = useState(logo)


  useEffect(() => {
    async function siteSettingData() {
      try {
        const res = await postData("site-settings/details", {

        });

        if (res.status === 200) {
          let data = res.data;
          setSiteSettingsDetails(data)



          setId(data?._id);
          setCompanyTitle(data?.title);
          setDescription(data?.description);
          setEmail(data?.email);
          setPhone(data?.phone);
          setAddress(data?.address);
          setCopyRight(data?.copyright);
          setLogoId(data?.logo_id)
          setFavId(data?.favicon_id)
          setLogo(data?.logo)
          setFavicon(data?.favicon)






        }
      } catch (error) {
        console.log("Error site setting details catch", error.message);
      }
    }

    siteSettingData();


  }, []);


  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link)
        ;
    }
    link.href = favIcon;
  }, [favIcon])

  const handelSubmit = async () => {
    setLoading(true);

    if (id) {
      try {

        const res = await postData("/site-settings/manage", {
          _id: id,
          logo_id: logoId,
          favicon_id: favId,
          title: title,
          email: email,
          phone: phone,
          copyright: copyright,
          address: address,
          description: description


        });

        if (res.status === 200) {
          siteLogo(siteIcon)
          setLoading(false);
          toastr.success(res.message);
        } else {
          toastr.error(res.message);
        }
      } catch (error) {
        setLoading(false);
        toastr.error(error.message);
      }
    }

  };

  const onLogoChange = async (e) => {
    setLoading(true)
    setLogo(e.target.files[0]);
    setSiteIcon(e.target.files[0])
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const imgRes = await postData(
      "file/upload",
      formData,
      "multipart/form-data"
    );

    if (imgRes.status === 200) {
      setLoading(false)
      setLogoId(imgRes ? imgRes.data._id : logoId);
    } else {
      setLoading(false)
    }


  };

  const onFavChange = async (e) => {
    setLoading(true)
    setFavicon(e.target.files[0]);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const imgRes = await postData(
      "file/upload",
      formData,
      "multipart/form-data"
    );
    if (imgRes.status === 200) {
      setLoading(false)
      setFavId(imgRes ? imgRes.data._id : favId);
    } else {
      setLoading(false)
    }

  };
  const handelCancel = () => {
    if (id) {
      setCompanyTitle(SiteSettingsDetails?.title)
      setDescription(SiteSettingsDetails?.description)
      setEmail(SiteSettingsDetails?.email)
      setPhone(SiteSettingsDetails?.phone)
      setAddress(SiteSettingsDetails?.address)
      setCopyRight(SiteSettingsDetails?.copyright)
      setLogo(SiteSettingsDetails?.logo)
      setFavicon(SiteSettingsDetails?.favicon)


    }
    else {

      setCompanyTitle("");
      setDescription("");
      setEmail("");
      setPhone("");
      setAddress("");
      setCopyRight("");
      setLogo("");
      setFavicon("")
      setLogoId(null)
      setFavId(null)
    }

  }

  return (
    <>
      <div className="relative flex justify-between items-center mb-4">
        <div className="text-3xl text-slate-800 font-bold">Settings</div>
      </div>
      <div className="relative flex gap-4 ">
        <div className="w-[255px] space-y-4">
          <div className="relative bg-white rounded shadow">
            <div className="py-6 px-6 flex flex-col space-y-4 relative">
              <div className="w-full h-52 overflow-hidden rounded-md">
                {logo && logo instanceof File ? (
                  <Image
                    src={URL.createObjectURL(logo)}
                    alt={"Admin"}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-contain"}
                    id={"image1"}
                  />
                ) : (
                  <Image
                    src={logo}
                    alt={"Admin"}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-contain"}
                    id={"image1"}
                  />
                )}
              </div>
            </div>
            <div className="divide-x divide-slate-200 flex overflow-hidden rounded-b border-t border-slate-200">
              <ButtonFile
                buttonType={"button"}
                buttonIcon={
                  false ? "fa-light fa-spinner fa-spin" : "fa-light fa-image"
                }
                buttonIconPosition={"left"}
                buttonLabel={"Change Logo"}
                buttonClasses={
                  "!bg-white !text-slate-500 rounded-none w-full hover:!bg-sky-500 hover:!text-white"
                }
                functions={onLogoChange}
                accepts={"image/*"}
              />
            </div>
          </div>

          <div className="relative bg-white rounded shadow">
            <div className="py-6 px-6 flex flex-col space-y-4 relative">
              <div className="w-full h-52 overflow-hidden rounded-md">
                {favIcon && favIcon instanceof File ? (
                  <Image
                    src={URL.createObjectURL(favIcon)}
                    alt={"Admin"}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-contain"}
                    id={"image2"}
                  />
                ) : (
                  <Image
                    src={favIcon}
                    alt={"Admin"}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-contain"}
                    id={"image2"}
                  />
                )}
              </div>
            </div>
            <div className="divide-x divide-slate-200 flex overflow-hidden rounded-b border-t border-slate-200">
              <ButtonFile
                buttonType={"button"}
                buttonIcon={
                  false ? "fa-light fa-spinner fa-spin" : "fa-light fa-image"
                }
                buttonIconPosition={"left"}
                buttonLabel={"Change Favicon"}
                buttonClasses={
                  "!bg-white !text-slate-500 rounded-none w-full hover:!bg-sky-500 hover:!text-white"
                }
                functions={onFavChange}
                accepts={"image/*"}
              />
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="relative bg-white rounded shadow divide-y divide-slate-200">
            <div className="py-8 px-6 flex flex-col">
              <div className="text-xl font-bold text-slate-900">
                Site Settings
              </div>
              <div className="space-y-4 mt-4">
                <div className="relative">
                  <Input
                    label={"Company Name"}
                    labelClasses={"!text-xs"}
                    inputType={"text"}
                    inputPlaceholder={"Enter Site Title"}
                    inputValue={title}
                    setInput={setCompanyTitle}
                  />
                </div>


                <div className="flex items-start gap-4">
                  <div className="relative w-full">
                    <Input
                      label={"Email"}
                      labelClasses={"!text-xs"}
                      inputType={"email"}
                      inputPlaceholder={"Enter Email Id"}
                      inputValue={email}
                      setInput={setEmail}
                    />
                  </div>
                  <div className="relative w-full">
                    <Input
                      label={"Phone"}
                      labelClasses={"!text-xs"}
                      inputType={"number"}
                      inputPlaceholder={"Enter Phone Number"}
                      inputValue={phone}
                      setInput={setPhone}
                    />
                  </div>
                </div>
                <div className="relative">
                  <Textarea
                    label={"Address"}
                    labelClasses={"!text-xs"}
                    inputPlaceholder={"Enter Address"}
                    inputValue={address}
                    setTextarea={setAddress}
                  />
                </div>

              </div>
            </div>

            <div className="py-3 px-3 border-t border-slate-200">
              <div className="flex items-center justify-end gap-4">
                <Button
                  buttonType={"button"}
                  buttonIcon={
                    false ? "fa-light fa-spinner fa-spin" : "fa-light fa-times"
                  }
                  buttonIconPosition={"left"}
                  buttonLabel={"Cancel"}
                  buttonClasses={
                    "!bg-slate-200 !text-slate-600 border border-slate-300"
                  }
                  functions={handelCancel}
                  buttonHasLink={false}
                  buttonDisabled={false}
                />
                <Button
                  buttonType={"button"}
                  buttonIcon={
                    loading
                      ? "fa-light fa-spinner fa-spin"
                      : "fa-light fa-check"
                  }
                  buttonIconPosition={"left"}
                  buttonLabel={"Save"}
                  functions={handelSubmit}
                  buttonHasLink={false}
                  buttonDisabled={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    siteState: state.siteLogo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    siteLogo: (logo) => dispatch(siteSettingsImage(logo))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
