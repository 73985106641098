import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import Input from "../components/form/Input";
import Button from "../components/form/Button";
import toastr from 'toastr';

import { postData } from "../services/api";
import { useParams } from "react-router-dom";

const UserManage = () => {
    const { id } = useParams()
    setTitle("Ryen bilpleie  | Manage User")

    const [isAdd,setIsAdd] = useState(false);
    const [name,setName] = useState('');
    const [phone,setPhone] = useState('');
    const [email,setEmail] = useState('');

    const onSubmit = async (e) => {
        setIsAdd(true);
        try {
            const result = await postData('assign/create', {
                name : name,
                phone:phone,
                email:email
            });
            if (result.status && result.status === 200) {        
                toastr.success(result.message);
                setIsAdd(false);
            } else {
                toastr.error(result.message);
                setIsAdd(false);
            }
        } catch (error) {
            toastr.error(error.message);
            setIsAdd(false);
        }
    }

    return(
        <>
        <div className="relative d-flex justify-between items-center mb-4">
            <div className="text-3xl text-slate-800 font-bold">Manage User</div>
        </div>
        <div className="relative grid grid-cols-2 gap-4">
            <div className="col-span-2">
              <div className="relative bg-white rounded shadow divide-y divide-slate-200">
                <div className="py-8 px-6 flex flex-col">
                  <div className="space-y-4 mt-4">
                    <div className="flex items-start gap-4">
                      <div className="relative w-full">
                        <Input 
                        label={'Full Name'} 
                        labelClasses={'!text-xs'} 
                        inputType={'text'}
                        inputPlaceholder={'Enter full name'}
                        inputValue={name}  
                        setInput={setName} 
                        />
                      </div>
                      <div className="relative w-full">
                        <Input 
                        label={'Phone'} 
                        labelClasses={'!text-xs'} 
                        inputType={'text'}
                        inputPlaceholder={'Enter phone'}
                        inputValue={phone}  
                        setInput={setPhone} 
                        />
                      </div>
                      <div className="relative w-full">
                        <Input
                        label={"Email"}
                        labelClasses={"!text-xs"}
                        inputType={"email"}
                        inputPlaceholder={"Email address"}
                        inputValue={email}
                        setInput={setEmail}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="py-3 px-3 border-t border-slate-200">
                  <div className="flex items-center justify-end gap-4">
                    <Button
                    buttonClasses=''
                    buttonLabelClasses='' 
                    buttonType={'button'} 
                    buttonIcon={(isAdd)?'fa-light fa-spinner fa-spin':'fa-light fa-check'} 
                    buttonIconPosition={'left'} 
                    buttonLabel={'Submit'}
                    functions={onSubmit}
                    buttonHasLink={false}
                    buttonDisabled={isAdd}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
    )
}
export default UserManage;