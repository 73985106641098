import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import Table from '../components/elements/table/Table';
import TableAlt from "../components/elements/table/TableAlt";
import ListLayout from "../containers/ListLayout";
import { postData } from "../services/api";
// import { convertTimezone, formatDate } from '../helpers';
// import AddEditNutritionModal from "../components/modals/AddEditNutritionModal";
import AddEditUsersModal from "../components/modals/AddEditUsersModal";
import AddEditAdminModal from "../components/modals/AddEditAdminModal";
import AddEditDriversModal from "../components/modals/AddEditDriversModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setTitle } from "../helpers/MetaTag";

const Users = () => {
  setTitle("Ryen bilpleie | Manage Users")
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const history = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get('company_id'); // "testCode"
  console.log(code);

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [list, setList] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [sortField, setSortField] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [company_id,setCompany_id]=useState("")

  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState({});

  useEffect(() => {
    if (user?._id) {
      let type = "";
      if (window.location.pathname === "/users") {
        type = "company";
      } else if (window.location.pathname === "/admin") {
        type = "admin";
      } else if (window.location.pathname === "/drivers") {
        type = "driver";
      }

      const loadData = async () => {
        setList((list) => ({ ...list, loading: true }));
        const searchParams = {};
        if (keyword) {
          searchParams.name = { $regex: keyword, $options: "i" };
        }

        const res = await postData("user/list", {
          type: type,
          keyword: keyword,
          limit: limit,
          offset: offset,
          company_id: code,
          sortQuery: sortField && sortType ? { [sortField]: sortType } : { ordering: 1 },
        });
        if (res.status === 200) {
          setList((list) => ({
            ...list,
            data: res.data,
            loading: false,
            count: res.count,
          }));
        } else {
          setList((list) => ({ ...list, loading: false }));
        }
      };

      loadData();
    }
  }, [
    user?._id,
    keyword,
    limit,
    offset,
    sortField,
    sortType,
    company_id,
    window.location.pathname,
  ]);

  useEffect(()=>{
    setCompany_id("");
  },[window.location.pathname])

  const Appointments = (id) => {
    if (window.location.pathname === "/drivers") {
      navigate(`/appoinments?driver_id=${id}`);
    } else {
      navigate(`/appoinments?company_id=${id}`);
    }
  };
  const Drivers = (id) => {
    if (window.location.pathname === "/users") {
      navigate(`/drivers?company_id=${id}`);
    }
  };

  const tableHeadData = [
    {
      _id:12,
      width:15,
      title:"company ",
      name:"company_id",
      align:'left',
      sortable:true,
      filterable:false,
      dataType:"object",
      dataKey:"name",
    },
    {
      _id: 1,
      width: 15,
      title: "Name",
      name: "name",
      align: "left",
      sortable: true,
      filterable: false,
      onClick: (id) => {
        console.log(editData, "editdataclick");
        setShowModal(true);
        setEditData(list.data.find((item) => item._id === id) || {});
      },
      
    },
    {
      _id: 2,
      width: 15,
      title: "email",
      name: "email",
      align: "left",
      sortable: true,
      filterable: false,
    },

    {
      _id: 3,
      width: 15,
      title: "phone",
      name: "phone",
      align: "left",
      sortable: true,
      filterable: false,
    },

    {
      _id: 4,
      width: 15,
      title: "Created At",
      name: "created_at",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 5,
      title: "Action",
      action: true,
    },
  ];
  
  const tableHeadDataUsr = [
    {
      _id: 1,
      width: 15,
      title: "Name",
      name: "name",
      align: "left",
      sortable: true,
      filterable: false,
      onClick: (id) => {
        console.log(editData, "editdataclick");
        setShowModal(true);
        setEditData(list.data.find((item) => item._id === id) || {});
      },
    },
    {
      _id: 2,
      width: 15,
      title: "email",
      name: "email",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 3,
      width: 15,
      title: "phone",
      name: "phone",
      align: "left",
      sortable: true,
      filterable: false,
    },

    {
      _id: 4,
      width: 15,
      title: "Created At",
      name: "created_at",
      align: "left",
      sortable: true,
      filterable: false,
    },
    {
      _id: 5,
      title: "Action",
      action: true,
    },
  ]; 

  let type = "";
  if (window.location.pathname === "/users") {
    type = "user";
  } else if (window.location.pathname === "/admin") {
    type = "admin";
  } else if (window.location.pathname === "/drivers") {
    type = "driver";
  }


  let actionData = (item) => [
    {
      name: "Edit",
      icon: "fa-pen-to-square",
      type: "button",
      onClick: (id) => {
        setShowModal(true);
        setEditData(list.data.find((item) => item._id === id) || {});
      },
    },
    window.location.pathname === "/users" && 
    {
      name: "Drivers",
      icon: "fa-light fa-fw fa-solid fa-car",
      type: "button",
      onClick: (id) => Drivers(id),
    },
 
window.location.pathname !=="/admin"&&
    {
      name: "Appointments",
      icon: "fa-light fa-fw fa-solid fa-calendar-days",
      type: "button",
      onClick: (id) => Appointments(id),
    },
    !["/admin", '/drivers'].includes(window.location.pathname) &&
    {
      name: "Invoice",
      icon: "fa-light fa-fw fa-solid  fa-file-invoice",
      type: "button",
      onClick: (id) => {
        navigate(`/invoices?company_id=${item._id}`)
      },
    },

    user?._id !== item._id &&
    {
      name: "Delete",
      
      
      icon: "fa-trash-can",
      type: "button",
      actionType: "delete",
      deleteLink: "/crud/delete",
      deletePayload: { id: [item._id],type:type,table_name:"user"},
    },
    
  ];


  return (
    <>
      <ListLayout
        title={
          window.location.pathname === "/users"
            ? "Companies"
            : window.location.pathname === "/admin"
            ? "Admins"
            : window.location.pathname === "/drivers"
            ? "Drivers"
            : ""
        }
        addName="Add"
        onAdd={() => setShowModal(true)}
        limit={limit}
        setLimit={setLimit}
        setKeyword={setKeyword}
        keyword={keyword}
        noOfPage={Math.ceil(list.count / limit)}
        onPageChange={(val) => setOffset(val.selected * limit)}
        setOffset={setOffset}
        setCompany_id={(id) => {id === 0 ?  setSearchParams({company_id: ""}) : setSearchParams({company_id: id}); setCompany_id(id);}}
      >
        {window.location.pathname === "/drivers" ? 
        <TableAlt
        tableHeadData={tableHeadData}
        tableData={list.data}
        loading={list.loading}
        sortField={sortField}
        sortType={sortType}
        setSortField={setSortField}
        setSortType={setSortType}
        actionData={actionData}
        setList={setList}
        isDraggable={false}
      /> :
       <TableAlt
          tableHeadData={tableHeadDataUsr}
          tableData={list.data}
          loading={list.loading}
          sortField={sortField}
          sortType={sortType}
          setSortField={setSortField}
          setSortType={setSortType}
          actionData={actionData}
          setList={setList}
          isDraggable={false}
        />
        }
        
      </ListLayout>
      {window.location.pathname === "/users" && (
        <AddEditUsersModal
          show={showModal}
          setShow={setShowModal}
          title={
            Object.keys(editData).length > 0 ? "Edit Company" : "Add Company"
          }
          list={list.data}
          setList={setList}
          editData={editData}
          setEditData={setEditData}
        />
      )}

      {window.location.pathname === "/admin" && (
        <AddEditAdminModal
          show={showModal}
          setShow={setShowModal}
          title={Object.keys(editData).length > 0 ? "Edit Admin" : "Add Admin"}
          list={list.data}
          setList={setList}
          editData={editData}
          setEditData={setEditData}
        />
      )}
      {window.location.pathname === "/drivers" && (
        <AddEditDriversModal
          show={showModal}
          setShow={setShowModal}
          title={Object.keys(editData).length > 0 ? "Edit Driver" : "Add Driver"}
          list={list.data}
          setList={setList}
          editData={editData}
          setEditData={setEditData}
        />
      )}
    </>
  );
};

export default Users;
